/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import dashboardLogo from "assets/img/logo.png";

import routes from "routes.js";

const Sidebar = ({ open, onClose }) => {
    return (
        <div
            className={`w-[272px] h-full sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all md:!z-50 lg:!z-50 xl:!z-50 ${
                open ? "translate-x-0" : "-translate-x-96"
            }`}
        >
            <span
                className="absolute top-4 right-4 block cursor-pointer xl:hidden"
                onClick={onClose}
            >
                <HiX />
            </span>

            <div className={`px-8 my-[20px] flex gap-2 items-center`}>
                <img
                    className="w-14"
                    src={dashboardLogo}
                    alt="Taraswin Dashboard"
                />
                <div className="font-poppins text-[24px] font-bold uppercase text-textDefault flex flex-col">
                    TARASWIN<span className="font-medium -mt-2">DASHBOARD</span>
                </div>
            </div>
            <div className="mb-7 h-px bg-gray-300 dark:bg-white/30" />
            {/* Nav item */}

            <ul className="mb-auto pt-1 overflow-y-auto custom-scroll">
                <Links routes={routes} />
            </ul>

            {/* Nav item end */}
        </div>
    );
};

export default Sidebar;
