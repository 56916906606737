import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
} from "@chakra-ui/modal";
import Text from "components/Text";
import Button from "components/Button";
     
const ModalContainer = (props) => {
    const { isOpen = false, modalTitle = '', isSubmitting, children, onClose = () => {}, onSubmit = () => {}, onDraft, hideButton } = props;
    const containerClass = `px-7 pt-7 pb-4 flex flex-col`;
    return (
        <Modal isOpen={isOpen} onClose={onClose} className="z-[2]">
            <ModalOverlay className="bg-[#000] !opacity-30" zIndex='2000' />
            <ModalContent
                className={`mt-auto sm:!m-auto sm:!w-fit md:top-[12vh] bg-white ${containerClass} rounded-2xl`}
                containerProps={{
                    zIndex: '2000',
                }}
            >
                <ModalBody className="sm:h-auto">
                    <Text variant="h1" content={modalTitle} />
                    <div className="border-b-[1px] border-[#E4E5E8] my-3"></div>
                    <div className="mt-3 mb-3 overflow-y-auto max-h-[50vh] h-auto">
                        {children}
                    </div>
                </ModalBody>
                {hideButton ? <div className="flex items-end justify-end">
                    <Button label="Batal" disabled={isSubmitting} variant="SECONDARY" outline onClickHandler={onClose} size="sm" customStyle="w-fit justify-center" />
                </div>
                : (
                    <ModalFooter>
                        <div className="w-full flex justify-between">
                            <div className="w-1/2">
                                {onDraft && (
                                    <Button label="Simpan Draft" disabled={isSubmitting} variant="SECONDARY" outline onClickHandler={onDraft} size="sm" customStyle="w-fit justify-center" />
                                )}
                            </div>
                            
                            <div className="w-1/2 flex gap-2 justify-end mt-2 w-fit">
                                <Button label="Batal" disabled={isSubmitting} variant="SECONDARY" outline onClickHandler={onClose} size="sm" customStyle="w-fit justify-center" />
                                <Button label="Simpan" disabled={isSubmitting} variant="DEFAULT" onClickHandler={onSubmit} size="sm" customStyle="w-fit justify-center" />
                            </div>
                        </div>
                    </ModalFooter>
                 )}
            </ModalContent>
        </Modal>
    ); 
};

export default ModalContainer;