import ActionColumn from "components/Table/components/ActionColumn";
import DefaultColumn from "components/Table/components/DefaultColumn";
import * as yup from "yup";

const validations = yup.object({
    sales_name: yup.string().required("Nama Sales wajib diisi"),
    id_region: yup.string().required("Wilayah Penjualan wajib diisi"),
    id_sales_role: yup.string().required("Role wajib diisi"),
    email: yup.string().email()
        .when("id_sales_role", {
            is: '1',
            then: () => yup.string().required("Email wajib diisi")
        }),
    password: yup.string()
        .when("id_sales_role", {
            is: '1',
            then: () => yup.string().required("Kata Sandi wajib diisi")
        }),
    confirm_password: yup.string()
        .when("id_sales_role", {
            is: '1',
            then: () => yup.string().required("Kata Sandi wajib diisi").oneOf([yup.ref('password')], 'Kata Sandi tidak sama')
        }),
    pin: yup.string()
        .when("id_sales_role", {
            is: '2',
            then: () => yup.string().required("PIN wajib diisi")
        }),
    confirm_pin: yup.string()
        .when("id_sales_role", {
            is: '2',
            then: () => yup.string().required("PIN wajib diisi").oneOf([yup.ref('pin')], 'PIN tidak sama')
        }),
});

const TABLE_COLUMN = (onEdit, onDelete) =>[
    {
        accessorKey: 'sales_name',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
        header: 'Nama Sales',
    },
    {
        accessorKey: 'sales_role.role_name',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
        header: 'Role',
    },
    {
        accessorKey: 'region.region_name',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
        header: 'Wilayah Penjualan',
    },
    {
        accessorKey: 'action',
        header: 'Aksi',
        size: 125,
        cell: info => <ActionColumn {...info} onClickEdit={onEdit} onClickDelete={onDelete} />,
        enableSorting: false,
    },
];

export {
    TABLE_COLUMN,
    validations,
}
