import React, { useState, useEffect } from 'react';

const SetValueContext = (parentProps) => {
    const [headerComponent, setHeaderComponent] = useState(null);

    // useEffect(() => {
    // }, []);

    return {
        ...parentProps,
        headerComponent,
        setHeaderComponent,
    };
};

export const LayoutContext = React.createContext();

const LayoutProvider = ({ children, parentProps }) => {
    const value = SetValueContext(parentProps);

    return (
        <LayoutContext.Provider value={{ ...value }}>
            {children}
        </LayoutContext.Provider>
    );
};

export default LayoutProvider;
