import { currency } from "helper";
import { numberSeparator } from "helper";
import { MdRemoveRedEye } from "react-icons/md";

const { default: DefaultColumn } = require("components/Table/components/DefaultColumn");

const TABLE_COLUMN_PRODUCT = [
    {
        accessorKey: 'product_name',
        header: 'Produk',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
        enableSorting: true,
    },
    {
        accessorKey: 'category_name',
        header: 'Kategori',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'qty',
        header: 'Total Penjualan (QTY)',
        cell: info => <DefaultColumn>{numberSeparator(info.getValue())}</DefaultColumn>,
    },
    {
        accessorKey: 'penjualan_kotor',
        header: 'Penjualan Kotor',
        cell: info => <DefaultColumn>{currency(info.getValue())}</DefaultColumn>,
    },
    {
        accessorKey: 'penjualan_bersih',
        header: 'Penjualan Bersih',
        cell: info => <DefaultColumn>{currency(info.getValue())}</DefaultColumn>,
    },
    {
        accessorKey: 'total_penjualan',
        header: 'Total Penjualan',
        cell: info => <DefaultColumn>{currency(info.getValue())}</DefaultColumn>,
    },
];

const TABLE_COLUMN_PAYMENT = setDetail => [
    {
        accessorKey: 'payment_method_name',
        header: 'Metode Pembayaran',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'qty',
        header: 'Total Penjualan (QTY)',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'bank_list',
        header: 'Total Penjualan',
        cell: info => <DefaultColumn>{currency(info.getValue()?.reduce((prev, curr) => prev + curr.value, 0))}</DefaultColumn>,
    },
    {
        accessorKey: 'action',
        header: 'Aksi',
        size: 70,
        cell: info => (
            <div className="flex gap-1">
                <button className="bg-[#BEDBFE] text-[#3A65FF] rounded-lg w-8 h-8 flex justify-center items-center" onClick={() => {setDetail(info.row.original)}}>
                    <MdRemoveRedEye />
                </button>
            </div>
        ),
    },
]

const DETAIL_TABLE_COLUMN_PAYMENT = [
    {
        accessorKey: 'bank_name',
        header: 'Nama Wallet',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'qty',
        header: 'Total Penjualan (Qty)',
        cell: info => <DefaultColumn>{info.getValue() || '-'}</DefaultColumn>,
    },
    {
        accessorKey: 'value',
        header: 'Total Penjualan',
        cell: info => <DefaultColumn>{currency(info.getValue())}</DefaultColumn>,
    },
]

const SALES_TYPE = {
    PRODUCT: 1,
    PAYMENT: 2,
}

const TYPE_LIST = [
    {
      label: "Penjualan Per Produk",
      value: 1,
    },
    {
      label: "Penjualan Per Metode Pembayaran",
      value: 2,
    },
]

export {
    TABLE_COLUMN_PRODUCT,
    TABLE_COLUMN_PAYMENT,
    DETAIL_TABLE_COLUMN_PAYMENT,
    SALES_TYPE,
    TYPE_LIST
}
