import axios from "axios";
import { getToken } from "helper/localstorage";

export const uploadImage = async (payload) => {
  try {
    let formData = new FormData();
    formData.append("images[]", payload[0]);
    const token = getToken();
    const res = await axios.post(
      "https://api.taraswin.com/api/upload_product_image",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          access_token: token,
        },
      }
    );
    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};
