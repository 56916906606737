const TEXT_VARIANT = {
    h1: "font-bold text-2xl",
    h2: "font-bold text-xl",
    h3: "font-bold text-lg",
    p: "text-base",
    pMedium: "text-base font-medium",
    pBold: "text-base font-bold",
    caption: "text-xs",
    captionMedium: "text-xs font-medium",
    captionBold: "text-xs font-bold",
    link: "text-sm",
    linkMedium: "text-sm font-medium",
    linkBold: "text-sm font-bold",
};

const getTextVariant = (variant) => {
    const content = TEXT_VARIANT[variant] ? TEXT_VARIANT[variant] : TEXT_VARIANT.p;

    return content;
};

export {
    getTextVariant,
    TEXT_VARIANT,
};
