import { FiSearch } from "react-icons/fi";

const InputSearch = ({ rounded = false, changeHandler = () => {} }) => {
    const roundedSize = !rounded ? 'rounded-lg' : 'rounded-full';

    return (
        <div className={`flex h-[48px] w-full items-center ${roundedSize} bg-lightPrimary text-textDefault`}>
            <p className="pl-3 pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-textDefault" />
            </p>
            <input
                type="text"
                placeholder="Cari..."
                className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-textDefault outline-none placeholder:!text-gray-400sm:w-fit"
                onChange={changeHandler}
            />
        </div>
    );
}

export default InputSearch;
