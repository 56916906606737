import { useContext, useEffect, useState } from 'react';
import { MdOutlineFileUpload } from 'react-icons/md';
import Card from 'components/card';
import Button from 'components/Button';
import InputSelect from 'components/Form/InputSelect';
import { LayoutContext } from 'context/LayoutContext';
import InputSearch from 'components/Form/InputSearch';
import List from './component/List';
import Filter from './component/Filter';
import { ContextHistoryStock } from './context/ContextProvider';
import { useHistoryStockList } from './context/useHistoryStockList';
import ModalDetail from './component/ModalDetail';
import ModalDetailSNBN from './component/ModalDetailSNBN';
import { useToast } from '@chakra-ui/react';
import { getStockHistory } from 'apis/stock_history';

const HistoryStock = () => {
    const { setHeaderComponent } = useContext(LayoutContext);
    const { open, setOpen, openSN, setOpenSN } = useHistoryStockList();
    const [stockHistoryList, setStockHistoryList] = useState([]);
    const [detailData, setDetailData] = useState({});
    const [table, setTable] = useState({
        loading: false,
        pagination: { total: 1, current_page: 1, per_page: 10 },
    });
    const [query, setQuery] = useState({
        watch: {
        keyword: "",
        start_date: "",
        end_date: "",
        },
    });
    const toast = useToast();

    const fetchStockHistoryList = async (params = {}) => {
        try {
          const res = await getStockHistory(params);
          setStockHistoryList(res.data);
          setTable((prev) => ({
            ...prev,
            pagination: {
              total: res.last_page,
              per_page: res.per_page,
              current_page: res.current_page,
            },
          }));
        } catch (error) {
          toast({
            title: "Gagal mendapatkan data sales",
            status: "error",
            isClosable: true,
            position: "top-center",
          });
        }
    };

    useEffect(() => {
        setHeaderComponent(
            // <div className="flex h-full items-end justify-end gap-3">
            //     <Button
            //         label="Export Excel"
            //         variant="GHOST"
            //         size="sm"
            //         leftIcon={<MdOutlineFileUpload />}
            //     />
            // </div>
        );
    }, []);

    useEffect(() => {
        fetchStockHistoryList({
          page: 1,
          limit: 10,
          ...query.watch,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [query.watch]);

    return (
        <ContextHistoryStock.Provider
            value={{
                open,
                setOpen,
                openSN,
                setOpenSN,
                stockHistoryList,
                table,
                fetchStockHistoryList,
                detailData,
                setDetailData,
                query,
                setQuery,
            }}
        >
            <Card extra="w-full h-full p-6 sm:overflow-x-auto flex-grow mt-5 flex flex-col">
                <Filter />

                <List />

                <ModalDetail />

                <ModalDetailSNBN />
            </Card>
        </ContextHistoryStock.Provider>
    );
};

export default HistoryStock;
