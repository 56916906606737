import * as yup from "yup";

export const validations = yup.object({
  product_name: yup.string().required("Wajib diisi"),
  sku: yup.string().required("Wajib diisi"),
  id_category: yup.number().required("Wajib diisi"),
  id_gudang: yup.array().min(1).required("Wajib diisi"),
  description: yup.string().required("Wajib diisi"),
  capital_price: yup.number().min(1, 'Wajib diisi').required("Wajib diisi"),
  selling_price: yup.number().min(1, 'Wajib diisi').required("Wajib diisi"),
  status: yup.number().optional(),
});
