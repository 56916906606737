import { useContext } from 'react';
import { ContextPromo } from '../context/ContextProvider';
import Text from 'components/Text';
import InputDatepicker from 'components/Form/InputDatepicker';
import SwitchField from 'components/fields/SwitchField';
import InputTimepicker from 'components/Form/InputTimepicker';
import {
    optionBonusType,
    optionDays
} from '../config/options';
import { useToast } from "@chakra-ui/react";
import Checkbox from 'components/checkbox';
import Radio from 'components/radio';
import InputCurrency from 'components/Form/InputCurrency';
import InputUpload from 'components/Form/InputUpload';
import ModalContainer from 'components/Modal';
import { useFieldArray, useForm } from 'react-hook-form';
import { DEFAULT_VALUES_FORM } from '../config/enum';
import InputField from 'components/fields/InputField';
import { getErrorField } from 'helper/geterrorfield';
import { getLocations } from 'apis/locations';
import InputMultipleSelect from 'components/Form/InputMultipleSelect';
import { getProduct } from 'apis/product';
import moment from 'moment';
import { createPromo } from 'apis/promo';

const ModalCreate = () => {
    const toast = useToast();
    const { openCreate, setOpenCreate } = useContext(ContextPromo);
    const forms = useForm({ defaultValues: DEFAULT_VALUES_FORM });
    const days = useFieldArray({ control: forms.control, name: 'active_days', keyName: 'id' });

    const handleCheckDays = (e, { data, idx }) => {
        // Logic for all day
        if (forms.watch('active_days').includes(0) && data.value === 0) {
            return days.replace([]);
        }
        
        if (data.value === 0) {
            return days.replace([0, 1, 2, 3, 4, 5, 6, 7]);
        }
        // End of Logic for all day

        if (forms.watch('active_days').includes(data.value)) {
            return days.replace(forms.watch('active_days').filter(day => day !== data.value && day !== 0))
        }

        if (e.target.checked) {
            days.insert(idx, data.value);
        }
    }

    return (
        <ModalContainer
            isOpen={openCreate}
            modalTitle={`Tambah Promo`}
            isSubmitting={forms.formState.isSubmitting}
            onClose={() => { setOpenCreate(false) }}
            onSubmit={forms.handleSubmit(async (values) => {
                try {
                    const payload = {
                        ...values,
                        active_days: values.active_days.filter(v => v !== 0),
                        start_at: moment(values.start_at).format('YYYY-MM-DD'),
                        end_at: moment(values.end_at).format('YYYY-MM-DD'),
                        active_hour_start: moment(values.active_hour_start).format('HH:mm'),
                        active_hour_end: moment(values.active_hour_end).format('HH:mm'),
                        promo_item_ids: values.is_transaction === '0' ? values.promo_item_ids : [],
                        is_transaction: +values.is_transaction,
                        minimum_criteria_type: +values.minimum_criteria_type,
                        minimum_criteria_value: +values.minimum_criteria_value,
                        bonus_type: {
                            bonus_id: +values.bonus_type.bonus_id,
                            bonus_value: +values.bonus_type.bonus_value,
                            is_percentage: +(values.bonus_type.bonus_id === '3'),
                            bonus_item_ids: values.bonus_type.bonus_id === '4' ? values.bonus_item_ids : []
                        }
                    }

                    await createPromo(payload);

                    setOpenCreate(false);

                    forms.reset(DEFAULT_VALUES_FORM);

                    toast({
                        title: 'Sukses',
                        description: 'berhasil membuat promo',
                        status: 'success',
                        position: 'top'
                    });
                } catch (err) {
                    toast({
                        title: 'Gagal',
                        description: err,
                        status: 'error',
                        position: 'top'
                    })
                }
            })}
        >
            <div className='flex flex-col gap-3 mr-2'>
                <div className="mb-3 flex items-center justify-between">
                    <div className="flex flex-col gap-1">
                        <Text variant="h3" content="Status Promo" />
                    </div>
                    <div className="w-1/7">
                        <SwitchField customStyle="gap-0" disabled defaultChecked />
                    </div>
                </div>
                <Text
                    variant="h3"
                    content="Informasi Promo"
                    customStyle="my-3"
                />
                <div className="mb-2 flex gap-4">
                    <div className="flex-1">
                        <InputField
                            register={forms.register('promo_name')}
                            label="Nama Promo"
                            required
                            state={
                                getErrorField(forms.formState.errors, "promo_name").show
                                ? "error"
                                : ""
                            }
                            errors={getErrorField(forms.formState.errors, "promo_name")}
                        />
                    </div>
                    <div className="flex-1">
                        <InputField
                            register={forms.register('description')}
                            label="Deskripsi Promo"
                            required
                            state={
                                getErrorField(forms.formState.errors, "description").show
                                ? "error"
                                : ""
                            }
                            errors={getErrorField(forms.formState.errors, "description")}
                        />
                    </div>
                </div>
                <div className="mb-2 flex gap-4">
                    <div className="w-1/2">
                        <InputMultipleSelect
                            required
                            fetchList={getLocations}
                            keyLabel="region_name"
                            keyValue="id_region"
                            initialLabel="Pilih wilayah"
                            label="Area/Kota Promo"
                            onChange={value => {
                                forms.setValue("id_region", value, { shouldValidate: true });
                            }}
                            value={forms.watch("id_region")}
                        />
                    </div>
                    <div className="w-1/2"></div>
                </div>
                <Text
                    variant="h3"
                    content="Waktu dan Durasi Promo"
                    customStyle="my-3"
                />
                <div className="mb-2 flex gap-4">
                    <div className="flex-1">
                        <InputDatepicker
                            label="Tanggal Mulai"
                            className="h-[50px] w-full"
                            onChange={(e) => forms.setValue("start_at", e)}
                            value={forms.watch("start_at")}
                            required
                        />
                    </div>
                    <div className="flex-1">
                        <InputDatepicker
                            label="Tanggal Berakhir"
                            className="h-[50px] w-full"
                            onChange={(e) => forms.setValue("end_at", e)}
                            value={forms.watch("end_at")}
                            required
                        />
                    </div>
                </div>
                <div className="mb-2 flex gap-4">
                    <div className="flex-1">
                        <InputTimepicker
                            label="Jam Mulai"
                            className="h-[50px] w-full"
                            onChange={(e) => forms.setValue("active_hour_start", e)}
                            value={forms.watch("active_hour_start")}
                            required
                        />
                    </div>
                    <div className="flex-1">
                        <InputTimepicker
                            label="Jam Mulai"
                            className="h-[50px] w-full"
                            onChange={(e) => forms.setValue("active_hour_end", e)}
                            value={forms.watch("active_hour_end")}
                            required
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <Text variant="linkBold" content="Hari Aktif" />
                    <div className="flex gap-4">
                        {optionDays.section1.map((x, idx) => (
                            <div className="flex-1">
                                <Checkbox
                                    id={x.value}
                                    label={x.label}
                                    checked={forms.watch('active_days').includes(x.value)}
                                    onChange={e => handleCheckDays(e, { data: x, idx })}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="flex gap-4">
                        {optionDays.section2.map((x, idx) => (
                            <div className="flex-1">
                                <Checkbox
                                    id={x.value}
                                    label={x.label}
                                    checked={forms.watch('active_days').includes(x.value)}
                                    onChange={e => handleCheckDays(e, { data: x, idx })}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <Text
                    variant="h3"
                    content="Syarat dan Bonus Promo"
                    customStyle="my-3"
                />
                <div className="mb-3 flex flex-col gap-2">
                    <Text
                        variant="linkBold"
                        content="Jenis Promo"
                    />
                    <div className="flex gap-6">
                        <Radio label="Promo per Produk" register={forms.register('is_transaction')} value={0} />
                        <Radio label="Promo per Transaksi" register={forms.register('is_transaction')} value={1} />
                    </div>
                </div>
                {forms.watch('is_transaction') === '0' && (
                    <div className="mb-2 flex gap-4">
                        <div className="w-full">
                            <InputMultipleSelect
                                required
                                isMultiple
                                label="Produk Syarat"
                                fetchList={getProduct}
                                keyLabel="product_name"
                                keyValue="id_product"
                                initialLabel="Pilih produk"
                                getLabels={labels => forms.setValue('promo_item_labels', labels)}
                                onChange={(value) => forms.setValue("promo_item_ids", value, { shouldValidate: true })}
                                value={forms.watch("promo_item_ids", [])}
                            />

                            <div className="mt-2 flex items-center gap-2">
                                {forms.watch('promo_item_labels', []).map(label => <span className='px-2 bg-[#eeeeee] rounded-xl'>{label}</span>)}
                            </div>
                        </div>
                    </div>
                )}
                <div className="mb-3 flex flex-col gap-2">
                    <Text
                        variant="linkBold"
                        content="Promo Berdasarkan"
                        required
                    />
                    <div className="flex gap-6">
                        <Radio label="Min. Pembelian (Rp)" register={forms.register('minimum_criteria_type')} value={1} />
                        <Radio label="Min. Kuantitas (Jumlah)" register={forms.register('minimum_criteria_type')} value={2} />
                    </div>
                </div>
                <div className="mb-2 flex w-full">
                    <InputCurrency hideCurrency={forms.watch('minimum_criteria_type') === '2'} extra="w-full" register={forms.register('minimum_criteria_value')} />
                </div>
                <div className="mb-3 flex flex-col gap-2">
                    <Text variant="linkBold" content="Jenis Bonus" required />
                    <div className="flex gap-4">
                        {optionBonusType.map((x) => (
                            <div className="flex-1 rounded-lg border border-solid border-gray-300 p-1">
                                <Radio value={x.value} label={x.label} register={forms.register('bonus_type.bonus_id')} />
                            </div>
                        ))}
                    </div>
                    <div className="flex gap-4">
                        {forms.watch('bonus_type.bonus_id') === '4' ? (
                            <div className="w-full">
                                <InputMultipleSelect
                                    required
                                    isMultiple
                                    label="Produk Syarat"
                                    fetchList={getProduct}
                                    keyLabel="product_name"
                                    getLabels={labels => forms.setValue('bonus_item_labels', labels)}
                                    keyValue="id_product"
                                    initialLabel="Pilih produk"
                                    onChange={value => forms.setValue("bonus_item_ids", value, { shouldValidate: true })}
                                    value={forms.watch("bonus_item_ids", [])}
                                />
                                <div className="mt-2 flex items-center gap-2">
                                    {forms.watch('bonus_item_labels', []).map(label => <span className='px-2 bg-[#eeeeee] rounded-xl'>{label}</span>)}
                                </div>
                            </div>
                        ) : (
                            <InputCurrency hideCurrency={forms.watch('bonus_type.bonus_id') === '3'} extra="w-full" register={forms.register('bonus_type.bonus_value')} />
                        )}
                    </div>
                </div>
                <div className="mb-2 flex flex-col gap-2">
                    <Text variant="linkBold" content="Banner Promo" />
                    <div className="aspect-square w-1/4">
                        <InputUpload
                            accept="image/*"
                            setValue={(data) =>forms.setValue(`banner_path`, data[0].url)}
                            image={forms.watch(`banner_path`, "")}
                        />
                    </div>
                </div>
            </div>
            
        </ModalContainer>
    );
};

export default ModalCreate;
