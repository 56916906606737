//interceptor network (not used cause blocker in unitest)

/* eslint-disable no-param-reassign */
import Axios from 'axios';
import { URL_API } from 'config/keys';
import { getToken, removeUser } from 'helper/localstorage';

const httpService = Axios.create({
    baseURL: URL_API,
    timeout: 20000,
    headers: {
        'content-type': 'application/json',
        refreshToken: '',
    },
});

httpService.interceptors.request.use(
    // custom handle config
    (config) => {
        const token = getToken();
        const customToken = config.headers?.customToken;
        return {
            ...config,
            headers: {
                'content-type': 'application/json',
                ...(token && {
                    access_token: customToken || token,
                }),
            },
        };
    },
    (error) => Promise.reject(error),
);

httpService.interceptors.response.use(
    // custom handle response success
    async (res) => res.data,
    // custom handle response error
    async (error) => {
        if (error?.response) {
            const { status, config } = error.response;

            const authApi = ["/admin/login"];
            if (status === 401 && !authApi.includes(config.url)) {
                removeUser();

                return (window.location.href = '/auth/sign-in');
            }
        }

        return Promise.reject(error);
    },
);

export default httpService;