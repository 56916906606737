import DefaultColumn from "components/Table/components/DefaultColumn";
import { MdRemoveRedEye } from "react-icons/md";
import StatusColumn from "../component/StatusColumn";
import TypeColumn from "../component/TypeColumn";
import Button from "components/Button";

const STATUS_ENUM = {
    DRAFT: 1,
    PROCESS: 2,
    SENT: 3,
    RECEIVED: 4,
    CANCEL: 5,
}

const TYPE_ENUM = {
    TRANSFER: 1,
    PURCHASE: 2,
}

const TABLE_COLUMN = ({ handleOpenDetail }) => [
    {
        accessorKey: 'po_date',
        header: 'Tanggal',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'stock_type',
        header: 'Tipe Transaksi',
        cell: info => <TypeColumn type={info.getValue()} />,
    },
    {
        accessorKey: 'no_po',
        header: 'Nomor Referensi',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
        size: 250,
    },
    {
        accessorKey: 'username',
        header: 'Dibuat Oleh',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'status',
        header: 'Status',
        cell: info => <StatusColumn status={info.getValue()} original={info.row.original} />,
    },
    {
        accessorKey: 'Aksi',
        header: 'action',
        cell: info => (
            <div className="flex gap-1">
                <button className="bg-[#EBFAF0] text-[#215759] rounded-lg w-8 h-8 flex justify-center items-center" onClick={() => handleOpenDetail(info.row.original) }>
                    <MdRemoveRedEye />
                </button>
            </div>
        ),
    },
];

const TABLE_COLUMN_DETAIL = ({ setOpenSN }) => [
    {
        accessorKey: 'sku',
        header: 'SKU',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'product_name',
        header: 'Nama produk',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'qty',
        header: 'Qty',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'unit_name',
        header: 'Satuan',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'price',
        header: 'Harga Modal',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'notes',
        header: 'Catatan',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    // {
    //     accessorKey: 'snbn',
    //     header: 'Serial/Batch Number',
    //     cell: info => (
    //         <Button
    //             label="Lihat"
    //             variant="SECONDARY"
    //             onClickHandler={() => setOpenSN(true)}
    //             size="sm"
    //         />
    //     ),
    // },
]

const TABLE_COLUMN_DETAIL_SNBN = [
    {
        accessorKey: 'order',
        header: 'Urutan',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'sn',
        header: 'Serial Number',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
        size: 400
    },
]

const DUMMY_DATA = [
    {
        id: 1,
        date: '01 Januari 2024',
        type: 1,
        no: 'PO/220523/RQ7',
        created_by: 'Dwi Setiawan',
        status: 0,
    },
    {
        id: 2,
        date: '01 Januari 2024',
        type: 2,
        no: 'PO/220523/RQ7',
        created_by: 'Dwi Setiawan',
        status: 1,
    },
    {
        id: 3,
        date: '01 Januari 2024',
        type: 2,
        no: 'PO/220523/RQ7',
        created_by: 'Dwi Setiawan',
        status: 2,
    },
    {
        id: 4,
        date: '01 Januari 2024',
        type: 1,
        no: 'PO/220523/RQ7',
        created_by: 'Dwi Setiawan',
        status: 3,
    }
]

const DUMMY_DATA_DETAIL = [
    {
        id: 1,
        sku: 'SKU#12345',
        name: 'Bubble Gum Ice Cream',
        stock: {
            system: 120,
            actual: 100
        },
        remaining: 20,
        capital_price: 'Rp 10.000',
        note: '-',
    },
]

const DUMMY_DATA_DETAIL_SNBN = [
    { id: 1, order: 1, sn: 'ABC-1011E0001' },
    { id: 2, order: 2, sn: 'ABC-2022E0002' },
]

export {
    TABLE_COLUMN,
    TABLE_COLUMN_DETAIL,
    DUMMY_DATA,
    STATUS_ENUM,
    TYPE_ENUM,
    DUMMY_DATA_DETAIL,
    TABLE_COLUMN_DETAIL_SNBN,
    DUMMY_DATA_DETAIL_SNBN,
}
