import { getInputSize } from '../utils';
import Text from 'components/Text';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

const InputDateRange = ({
    label = '',
    size = 'md',
    value = [new Date(), new Date()],
    onChange = () => {},
    rounded,
    className
}) => {
    const roundedSize =
        size === 'sm' || size === 'xs' ? 'rounded-lg' : 'rounded-xl';

    return (
        <div className="flex flex-col gap-1">
            {label && <Text variant="linkBold" content={label} />}
            <ReactDatePicker
                showIcon
                selectsRange={true}
                placeholderText='Pilih Tanggal'
                className={`${
                    rounded ? 'rounded-full' : roundedSize
                } border bg-white ${getInputSize(size)} ${className}`}
                startDate={value[0]}
                endDate={value[1]}
                onChange={(date) => onChange(date)}
            />
        </div>
    );
};

export default InputDateRange;
