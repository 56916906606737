import React, { useContext } from "react";
import Table from "components/Table";
import { TABLE_COLUMN } from "../config/enum";
import { ContextTransaction } from "../context/ContextProvider";

const List = () => {
  const { query, setQuery, tableData, handleOpenDetail } = useContext(ContextTransaction);
  
  return (
    <div className="mt-8 flex flex-grow overflow-x-scroll xl:overflow-hidden">
      <Table
        columnsData={TABLE_COLUMN(handleOpenDetail)}
        tableData={tableData}
        paginationCallback={(page, offset) => {
          setQuery((prevQuery) => ({
            ...prevQuery,
            watch: { ...prevQuery.watch, page, limit: offset },
          }));
        }}
        totalData={query.total_data}
        currentPage={query.watch.page}
        perPage={query.watch.limit}
        pageCount={query.total_page}
      />
    </div>
  );
};

export default List;
