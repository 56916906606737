import httpService from "services/api.service";

const BASE_URL = '/user_sales';

export const getSales = async (payload) => {
    try {
        const res = await httpService.get(BASE_URL, { params: payload });
        return res;
    } catch (error) {
        throw error.message;
    }
};

export const createSales = async (payload) => {
    try {
      const res = await httpService.post(BASE_URL, payload);
  
      return res;
    } catch (error) {
      throw error.message;
    }
};

export const updateSales = async (payload) => {
  try {
    const res = await httpService.put(`/update_sales/${payload.id_sales}`, payload);

    return res;
  } catch (error) {
    throw error.message;
  }
};

export const deleteSales = async (payload) => {
  try {
    const res = await httpService.put(`/delete_sales/${payload.id_sales}`, payload);

    return res;
  } catch (error) {
    throw error.message;
  }
};
