const StatusColumn = ({ status, onEditStatus }) => {
  const text = {
    1: "Draft",
    2: "Process",
    3: "Sent",
    4: "Received",
    5: "Cancel",
  };

  const style = {
    1: "bg-[#E5E5E5] text-[#0A1A1B]",
    2: "bg-[#FEF2C7] text-[#E99518]",
    3: "bg-[#D8EAFF] text-[#3965FF]",
    4: "bg-[#EBFAF5] text-[#04C99E]",
    5: "bg-[#E5E5E5] text-[#0A1A1B]",
  };

  return (
    <div
      onClick={onEditStatus}
      className={`rounded-full px-2 py-1 tracking-wide hover:cursor-pointer hover:border-[1.5px] hover:border-brand-800 ${style[status]} w-fit text-xs`}
    >
      {text[status]}
    </div>
  );
};

export default StatusColumn;
