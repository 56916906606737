import httpService from "services/api.service";

export const createPromo = async (payload) => {
  try {
    const res = await httpService.post("/promo", payload);

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};

export const getPromo = async (params) => {
  try {
    const res = await httpService.get("/promo", { params });

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};

export const updatePromo = async (params) => {
  try {
    const res = await httpService.put(`/promo/${params.id}`, '');

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};