import { useContext, useEffect, useState } from 'react';
import { MdAdd, MdOutlineFileUpload } from 'react-icons/md';
import Card from 'components/card';
import Button from 'components/Button';
import { LayoutContext } from 'context/LayoutContext';
import Filter from './component/Filter';
import { ContextPromo } from './context/ContextProvider';
import { usePromoList } from './context/usePromoList';
import List from './component/List';
import ModalCreate from './component/ModalCreate';

const Promo = () => {
    const { setHeaderComponent } = useContext(LayoutContext);
    const { openCreate, setOpenCreate, optionSelected, handleChange } =
        usePromoList();
    const [table, setTable] = useState({
        loading: false,
        pagination: { total: 1, total_page: 1, current_page: 1, per_page: 10 },
    });
    const [query, setQuery] = useState({
        watch: {
          keyword: "",
          bonus_id: "",
          status: "",
        },
    });

    useEffect(() => {
        setHeaderComponent(
            <div className="flex h-full items-end justify-end gap-3">
                {/* <Button
                    label="Export Excel"
                    variant="GHOST"
                    size="sm"
                    leftIcon={<MdOutlineFileUpload />}
                /> */}
                <Button
                    label="Tambah Promo"
                    variant="DEFAULT"
                    size="sm"
                    leftIcon={<MdAdd />}
                    onClickHandler={() => setOpenCreate(true)}
                />
            </div>
        );
    }, []);

    return (
        <ContextPromo.Provider
            value={{
                openCreate,
                setOpenCreate,
                optionSelected,
                handleChange,
                table,
                setTable,
                query,
                setQuery,
            }}
        >
            <Card extra="w-full h-full p-6 sm:overflow-x-auto flex-grow mt-5 flex flex-col">
                <Filter />

                <List />

                <ModalCreate />
            </Card>
        </ContextPromo.Provider>
    );
};

export default Promo;
