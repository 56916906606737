import DefaultColumn from "components/Table/components/DefaultColumn";

const TABLE_COLUMN = [
    {
        accessorKey: 'product.product_name',
        header: 'Nama',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
        size: 500,
    },
    {
        accessorKey: 'gudang.gudang_name',
        header: 'Wilayah',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'stok',
        header: 'Stok',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'unit.unit_name',
        header: 'Satuan',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
];

export {
    TABLE_COLUMN,
}
