import httpService from "services/api.service";

export const getRegion = async (params) => {
  try {
    const res = await httpService.get("/region", { params });

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};
