import ActionColumn from "components/Table/components/ActionColumn";
import DefaultColumn from "components/Table/components/DefaultColumn";

const TableColumn = (onEdit, onDelete, onDetail) => [
  {
    accessorKey: "gudang_name",
    header: "Nama Gudang",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    size: 500,
  },
  {
    accessorKey: "region.region_name",
    header: "Provinsi/Wilayah",
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: "address",
    header: "Alamat",
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: "user_sales.sales_name",
    header: "PIC",
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: "user_sales.phone",
    header: "Nomor Kontak PIC",
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: "action",
    header: "Aksi",
    size: 125,
    cell: (info) => (
      <ActionColumn onClickEdit={onEdit} onClickDelete={onDelete} onClickDetail={onDetail} {...info} />
    ),
    enableSorting: false,
  },
];

export { TableColumn };
