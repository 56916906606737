import { useContext } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { ContextHistoryStock } from '../context/ContextProvider';
import Card from 'components/card';
import Text from 'components/Text';
import Button from 'components/Button';
import Table from 'components/Table';
import { DUMMY_DATA_DETAIL_SNBN, TABLE_COLUMN_DETAIL_SNBN } from '../config/enum';
import ModalContainer from 'components/Modal';

const ModalDetailSNBN = () => {
    const { openSN, setOpenSN } = useContext(ContextHistoryStock);
    const containerClass = `px-[30px] pt-[35px] pb-[40px] flex flex-col max-h-[550px] overflow-y-scroll`;

    return (
        <ModalContainer
            isOpen={openSN}
            modalTitle={`Serial/Batch Number`}
            onClose={() => { setOpenSN(false) }}
        >
            <Text variant="h3" content="Daftar Serial Number" />
            <Table
                columnsData={TABLE_COLUMN_DETAIL_SNBN}
                tableData={DUMMY_DATA_DETAIL_SNBN}
                hidePagination
            />
        </ModalContainer>
    );
};

export default ModalDetailSNBN;
