const StatusColumn = ({ status, type = 'product' }) => {
    const STATUS_ENUM = {
        ACTIVE: 1,
        INACTIVE: 0,
    }

    let label = '';
    if (type === 'product') {
        label = status === STATUS_ENUM.ACTIVE ? 'Tampil' : 'Tidak Tampil';
    }

    if (type === 'customer') {
        label = status === STATUS_ENUM.ACTIVE ? 'Aktif' : 'Tidak Aktif';
    }

    return (
        <div className={`rounded-full py-1 px-2 tracking-wide ${status === STATUS_ENUM.ACTIVE ? 'bg-[#EBFAF5] text-[#04C99E]' : 'bg-backgroundLight text-[#2B3674]'} w-fit text-xs`}>{label}</div>
    );
};
  
export default StatusColumn;
