import { useContext } from "react";
import { ContextPurchaseOrder } from "../context/ContextProvider";
import Text from "components/Text";
import { defaultFieldValues, FORM_MODE } from "../config/enum";
import ModalContainer from "components/Modal";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import Form from "./Form";
import { createStockOpname, updateStockOpname } from "apis/stock_opname";

const ModalCreate = () => {
  const { openCreate, setOpenCreate, fetchSO, toast, isDetail } =
    useContext(ContextPurchaseOrder);
  const forms = useFormContext();

  const handleSubmit = forms.handleSubmit(async (values) => {
    try {
      const formattedDetails = values.so_details.map((item) => ({
        ...(values.formType === FORM_MODE.EDIT && {
          id_stock_opname_detail: item.id_stock_opname_detail,
        }),
        id_product: item.id_product,
        qty: Number(item.stock_actual),
        price: item.price,
        notes: item.notes,
      }));

      const payload = {
        ...(values.formType === FORM_MODE.EDIT && {
          no_so: values.no_so,
          id_stock_opname: values.id_stock_opname,
        }),
        status: values.status,
        note: values.note,
        id_user: values.id_user,
        so_date: moment(values.so_date).format("YYYY-MM-DD"),
        id_gudang: values.id_gudang,
        so_details: formattedDetails,
      };

      if (values.formType === FORM_MODE.CREATE) {
        await createStockOpname(payload);
      } else {
        await updateStockOpname(payload);
      }

      setOpenCreate(false);

      forms.reset(defaultFieldValues);

      fetchSO({ page: 1, limit: 10 });
    } catch (error) {
      toast({
        title: error,
        status: "error",
        isClosable: true,
        position: "top-center",
      });
    }
  });

  const handleDraft = () => {
    forms.setValue("status", 1);
    handleSubmit();
  };

  return (
    <ModalContainer
      isOpen={openCreate}
      modalTitle={`${
        forms.watch("formType") === "create"
          ? "Tambah"
          : forms.watch("formType") === "detail"
          ? "Detail"
          : "Edit"
      } Stok Opname`}
      onClose={() => {
        setOpenCreate(false);
        forms.reset(defaultFieldValues);
      }}
      onSubmit={() => {
        forms.setValue("status", 2);
        handleSubmit();
      }}
      isSubmitting={forms.formState.isSubmitting}
      hideButton={isDetail}
      onDraft={handleDraft}
    >
      <Text variant="h3" content="Informasi Dasar" />
      <Form />
    </ModalContainer>
  );
};

export default ModalCreate;
