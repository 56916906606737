import { MdArrowDropDown } from "react-icons/md";
import { getInputSize } from "../utils";
import Text from "components/Text";

const InputSelect = ({ label = '', size = 'md', rounded = false, optionList = [], errors, state, disabled = false, required, extra, ...rest }) => {
    const roundedSize = size === 'sm' || size === 'xs' ? 'rounded-lg' : 'rounded-xl';

    return (
        <div className={`flex flex-col gap-2 ${extra}`}>
            {label && <div className="flex gap-1"><Text variant="linkBold" content={label} /> {required && <span className="text-[#DF2A36]">*</span>}</div>}

            <div className="inline-block relative w-full">
                <select
                    {...rest}
                    className={`appearance-none flex w-full items-center justify-center ${rounded ? 'rounded-full' : roundedSize} border bg-white outline-none ${getInputSize(size)} ${
                        disabled === true
                            ? "!border-none !bg-gray-100 dark:!bg-white/5 cursor-not-allowed dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                            : state === "error"
                            ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
                            : state === "success"
                            ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                            : "border-gray-200 dark:!border-white/10 dark:text-white"
                        }`}>
                    {optionList.map(list => (
                        <option disabled={disabled} key={`option-${list.value}`} value={list.value}>{list.label}</option>
                    ))}
                </select>
                <div className={`pointer-events-none absolute inset-y-0 right-2 flex items-center px-2 ${getInputSize(size)}`}>
                    <MdArrowDropDown />
                </div>
            </div>
            {errors?.show && <span className="text-[#DF2A36] text-[12px]">{errors?.message}</span>}
        </div>
        
    );
}

export default InputSelect;
