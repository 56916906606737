import Widget from 'components/widget/Widget';
import { currency } from 'helper';
import Text from 'components/Text';
import { useContext } from 'react';
import { ContextTransaction } from '../context/ContextProvider';

const IconWidget = ({ customClass }) => (
    <div className={`rounded-[50%] h-7 w-7 flex items-center justify-center ${customClass}`}>
        <Text variant="pBold" content="Rp" color="text-white" />
    </div>
)

const WidgetList = () => {
    const { query } = useContext(ContextTransaction);
    return (
        <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
            <Widget
                icon={<IconWidget customClass="bg-[#01B574]" />}
                title={'Transaksi Terbayar'}
                subtitle={currency(query.paid_value)}
            />
            <Widget
                icon={<IconWidget customClass="bg-[#E31A1A]" />}
                title={'Transaksi Belum Terbayar'}
                subtitle={currency(query.unpaid_value)}
            />
            <Widget
                icon={<IconWidget customClass="bg-[#215759]" />}
                title={'Total'}
                subtitle={currency(query.total_value)}
            />
        </div>
    );
};

export default WidgetList;
