import { numberSeparator } from "helper";
import moment from "moment";

const { default: DefaultColumn } = require("components/Table/components/DefaultColumn");

const TABLE_COLUMN = [
    {
        accessorKey: 'product.product_name',
        header: 'Produk',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
        enableSorting: true,
    },
    {
        accessorKey: 'gudang.gudang_name',
        header: 'Gudang',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'stok',
        header: 'Stok',
        cell: info => <DefaultColumn>{numberSeparator(info.getValue())}</DefaultColumn>,
    },
    {
        accessorKey: 'stock_in',
        header: 'Stok Masuk',
        cell: info => <DefaultColumn>{numberSeparator(info.getValue())}</DefaultColumn>,
    },
    {
        accessorKey: 'stock_out',
        header: 'Stok Keluar',
        cell: info => <DefaultColumn>{numberSeparator(info.getValue())}</DefaultColumn>,
    },
    {
        accessorKey: 'updated_at',
        header: 'Tanggal Transaksi',
        cell: info => <DefaultColumn>{moment(info.getValue()).format('DD MMMM YYYY')}</DefaultColumn>,
    },
];

export {
    TABLE_COLUMN,
}
