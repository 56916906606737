import { useContext, useEffect, useState } from 'react';
import { MdOutlineFileUpload } from 'react-icons/md';
import Button from 'components/Button';
import { LayoutContext } from 'context/LayoutContext';
import { ContextGeoLocation } from './context/ContextProvider';
import { useToast } from '@chakra-ui/react';
import { getSalesStock } from 'apis/sales_cash';
import MapWithClusters from 'components/MapComponent/MapWithClusters';
import { getGeoLocationReport } from 'apis/geo_location';

const GeoLocation = () => {
    const { setHeaderComponent } = useContext(LayoutContext);
    const toast = useToast();
    const [locationList, setLocationList] = useState([]);

    const fetchData = async (params = {}) => {
        try {
          const res = await getGeoLocationReport(params);
          setLocationList(res)
        } catch (error) {
          toast({
            title: "Gagal mendapatkan data sales",
            status: "error",
            isClosable: true,
            position: "top-center",
          });
        }
    };

    useEffect(() => {
        setHeaderComponent(
            <div className="flex h-full items-end justify-end gap-3">
                {/* <Button
                    label="Export Excel"
                    variant="GHOST"
                    size="sm"
                    leftIcon={<MdOutlineFileUpload />}
                /> */}
            </div>
        );

        fetchData();
    }, []);

    return (
      <div className='w-full p-6 rounded-xl h-fit bg-white mt-5'>
        <MapWithClusters height="70vh" locationList={locationList} />
      </div>
    );
};

export default GeoLocation;
