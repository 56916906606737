import InputField from "components/fields/InputField";
import React, { useState } from "react";

const InputCell = ({ info, callback, isDetail, isEdit, type = "number" }) => {
  const [localValue, setLocalValue] = useState(info.getValue());

  const handleBlur = () => {
    callback(localValue);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setLocalValue(value);
  };

  return (
    <InputField
      type={type}
      value={localValue}
      disabled={isDetail || isEdit}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default InputCell;
