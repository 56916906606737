import httpService from "services/api.service";

export const createGudang = async (payload) => {
  try {
    const res = await httpService.post("/gudang", payload);

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};

export const updateGudang = async (payload) => {
  try {
    const res = await httpService.put(`/gudang/${payload.id}`, payload);

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};

export const deleteGudang = async (payload) => {
  console.log(payload)
  try {
    const res = await httpService.delete(`/gudang/${payload.id}`);

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};

export const getGudang = async (params) => {
  try {
    const res = await httpService.get("/gudangs", { params });

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};
