import ActionColumn from "components/Table/components/ActionColumn";
import DefaultColumn from "components/Table/components/DefaultColumn";
import StatusColumn from "components/Table/components/StatusColumn";

const TableColumn = (onEdit, onDelete) => [
  {
    accessorKey: "category_name",
    header: "Nama Kategori",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    size: 500,
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: (info) => <StatusColumn status={info.getValue()} />,
  },
  {
    accessorKey: "action",
    header: "Aksi",
    size: 125,
    cell: (info) => <ActionColumn onClickEdit={onEdit} onClickDelete={onDelete} {...info} />,
    enableSorting: false,
  },
];

export { TableColumn };
