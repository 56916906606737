import httpService from "services/api.service";

const BASE_URL = '/customer';

export const getCustomers = async (payload) => {
    try {
        const res = await httpService.get(BASE_URL, { params: payload });
        return res;
    } catch (error) {
        throw error.response.data.msg;
    }
};

export const createCustomer = async (payload) => {
    try {
      const res = await httpService.post(BASE_URL, payload);
  
      return res;
    } catch (error) {
      throw error.message;
    }
};

export const updateCustomer = async (payload) => {
  try {
    const res = await httpService.put(`${BASE_URL}/${payload.id_customer}`, payload);

    return res;
  } catch (error) {
    throw error.message;
  }
};

export const deleteCustomer = async (payload) => {
  try {
    const res = await httpService.put(`/delete_customer/${payload.id_customer}`, payload);

    return res;
  } catch (error) {
    throw error.message;
  }
};

export const createUser = async (payload) => {
  try {
    const res = await httpService.post('/create-user', payload);

    return res;
  } catch (error) {
    throw error.message;
  }
};
