import Text from "components/Text";
import Switch from "components/switch";

const SwitchField = (props) => {
  const { id, label, desc, mt, mb, customStyle, onChange, value, defaultChecked } = props;
  return (
    <div className={`flex flex-col ${mt} ${mb} gap-3 ${customStyle}`}>
        <Text variant="linkBold" content={label} />
        <div className={`flex ${mt} ${mb} items-center gap-2`}>
          <Switch id={id} onChange={onChange} checked={value} defaultChecked={defaultChecked} />
          <label
              htmlFor={id}
              className="max-w-[80%] hover:cursor-pointer lg:max-w-[65%]"
          >
              <Text variant="linkBold" content={desc} />
          </label>
        </div>
    </div>
  );
};

export default SwitchField;
