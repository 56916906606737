import DefaultColumn from "components/Table/components/DefaultColumn";
import { MdCreate, MdDelete, MdRemoveRedEye } from "react-icons/md";
import StatusColumn from "../component/StatusColumn";
import * as yup from "yup";
import Checkbox from "components/checkbox";
import InputText from "components/Form/InputText";
import { getUser } from "helper/localstorage";
import { currency, debounce } from "helper/index";
import InputNumberColumn from "../component/InputCell";
import { useState } from "react";
import InputCell from "../component/InputCell";

const validations = yup.object({
    created_by: yup.string().required("Pembuat PO wajibdiisi"),
    id_gudang: yup.string().required("Gudang wajib diisi"),
    supplier_name: yup.string().required("Pemasok wajib diisi"),
    
});

const STATUS_ENUM = {
    DRAFT: 1,
    PROCESS: 2,
    SENT: 3,
    RECEIVED: 4,
    CANCEL: 5,
}

const FORM_MODE = {
    CREATE: 'create',
    EDIT: 'edit',
    DETAIL: 'detail',
}

const TABLE_COLUMN = (onDetail, onEdit, onEditStatus) => [
    {
        accessorKey: 'po_date',
        header: 'Tanggal',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'no_po',
        header: 'No. Purchase Order',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'supplier_name',
        header: 'Pemasok',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'user.username',
        header: 'Dibuat Oleh',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'status',
        header: 'Status',
        cell: info => <StatusColumn status={info.getValue()} onEditStatus={() => onEditStatus(info.row.original)} />,
    },
    {
        accessorKey: 'total',
        header: 'Tagihan (Rp)',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'id_product',
        header: 'Aksi',
        cell: info => {
            return (
                <div className="flex gap-2">
                    <button className="bg-[#EBFAF0] text-[#215759] rounded-lg w-8 h-8 flex justify-center items-center" onClick={() => onDetail(info.row.original)}>
                        <MdRemoveRedEye />
                    </button>
                    {info.cell.row.original.status === STATUS_ENUM.DRAFT && (
                        <button className="bg-[#BEDBFE] text-[#3A65FF] rounded-lg w-8 h-8 flex justify-center items-center" onClick={() => onEdit(info.row.original)}>
                            <MdCreate />
                        </button>
                    )}
                </div>
            )
        },
    },
];

const TABLE_COLUMN_PRODUCT_SELECTED = (handleDelete, handleChangeData, { isDetail, isEdit}) => {
    return [
        {
            accessorKey: 'product_name',
            header: 'Nama produk',
            cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
        },
        {
            accessorKey: 'sku',
            header: 'SKU',
            cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
        },
        {
            accessorKey: 'qty',
            header: 'Jumlah',
            cell: (info) => (
                <InputCell
                    info={info}
                    callback={value => handleChangeData('qty', value, info.row.original.id_product)} 
                    isDetail={isDetail} 
                    isEdit={isEdit} 
                />
            ),
        },
        // {
        //     accessorKey: 'unit',
        //     header: 'Satuan',
        //     cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
        // },
        {
            accessorKey: 'price',
            header: 'Harga Satuan',
            cell: (info) => (
                <InputCell
                    info={info} 
                    callback={value => handleChangeData('price', value, info.row.original.id_product)} 
                    isDetail={isDetail} 
                    isEdit={isEdit} 
                />
            ),
        },
        {
            accessorKey: 'total_price',
            header: 'Total Harga',
            cell: info => {
                const qty = parseInt(info.row.original.qty) || 0;
                const price = parseInt(info.row.original.price) || 0;
                const total = qty * price;
                return <DefaultColumn>{currency(total)}</DefaultColumn>
            },
        },
        {
            accessorKey: 'note',
            header: 'Catatan',
            cell: (info) => (
                <InputCell
                    type="text"
                    info={info} 
                    callback={value => handleChangeData('notes', value, info.row.original.id_product)} 
                    isDetail={isDetail} 
                    isEdit={isEdit} 
                />
            ),
        },
        {
            accessorKey: 'action',
            header: 'Aksi',
            cell: info => (
                <button disabled={isDetail || isEdit} className="disabled:cursor-not-allowed bg-[#FECACA] text-[#E31B1A] rounded-lg w-8 h-8 flex justify-center items-center" onClick={() => { handleDelete(info.row.original) }}>
                    <MdDelete />
                </button>
            ),
        },
    ]
}

const TABLE_COLUMN_PRODUCT = (onCheckTable) => {
    return [
        {
            accessorKey: 'id_product',
            header: '',
            size: 50,
            cell: info => (
                <Checkbox 
                    onChange={() => { onCheckTable(info.row.original) }}
                    value={info.row.original.id_product}
                    checked={info.row.original.checked}
                />
            )
        },
        {
            accessorKey: 'product_name',
            header: 'Nama Produk',
            cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
        },
        {
            accessorKey: 'sku',
            header: 'SKU',
            cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
        },
        {
            accessorKey: 'category.category_name',
            header: 'Kategori',
            cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
        },
        {
            accessorKey: 'capital_price',
            header: 'Harga Beli',
            cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
        },
        {
            accessorKey: 'selling_price',
            header: 'Harga Jual',
            cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
        },
    ]
}

const DUMMY_DATA = [
    {
        id: 1,
        date: '01 Januari 2024',
        no: 'PO/220523/RQ7',
        provider: 'CV Harapan Jaya',
        created_by: 'Dwi Setiawan',
        bill: 'Rp 2.000.000',
        status: 0,
    },
    {
        id: 2,
        date: '01 Januari 2024',
        no: 'PO/220523/RQ7',
        provider: 'CV Harapan Jaya',
        created_by: 'Dwi Setiawan',
        bill: 'Rp 2.000.000',
        status: 1,
    },
    {
        id: 3,
        date: '01 Januari 2024',
        no: 'PO/220523/RQ7',
        provider: 'CV Harapan Jaya',
        created_by: 'Dwi Setiawan',
        bill: 'Rp 2.000.000',
        status: 2,
    },
    {
        id: 3,
        date: '01 Januari 2024',
        no: 'PO/220523/RQ7',
        provider: 'CV Harapan Jaya',
        created_by: 'Dwi Setiawan',
        bill: 'Rp 2.000.000',
        status: 3,
    },
]

const DUMMY_DATA_PRODUCT = [
    { id: 1, name: 'Produk A', sku: 'SKU#12345', category: 'Makanan', purchase_price: 'Rp 50.000', selling_price: 'Rp 100.000' },
    { id: 2, name: 'Produk B', sku: 'SKU#12345', category: 'Minuman', purchase_price: 'Rp 50.000', selling_price: 'Rp 100.000' },
]

const defaultFieldValues = () => {
    return {
        id_user: getUser()?.id || '',
        created_by: getUser()?.name || '',
        supplier_name: '',
        po_no: '',
        id_gudang: '',
        status: 2,
        total: '0',
        other_cost: '',
        po_date: new Date(),
        po_details: [],
        formType: FORM_MODE.CREATE,
    }
} 

export {
    TABLE_COLUMN,
    DUMMY_DATA,
    STATUS_ENUM,
    TABLE_COLUMN_PRODUCT_SELECTED,
    TABLE_COLUMN_PRODUCT,
    DUMMY_DATA_PRODUCT,
    validations,
    defaultFieldValues,
    FORM_MODE,
}