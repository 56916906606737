import { getTextVariant } from "./utils";

const Text = ({ variant, color = 'text-textDefault', customStyle = '', content, required }) => {
    const cssClass = `${getTextVariant(variant)} ${color} ${customStyle}`;
    return (
        <div className={cssClass}>
            {content} {required && <span className="text-[#DF2A36]"> *</span>}
        </div>    
    );
};
  
export default Text;
