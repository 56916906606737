const BUTTON_TYPE = {
    DEFAULT: 'bg-brand-500 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-800',
    SECONDARY: 'bg-gray-100 font-medium text-textDefault transition duration-200 hover:bg-gray-200 active:bg-gray-300',
    DARK: 'bg-navy-700 font-medium text-white transition duration-200 hover:bg-navy-800 active:bg-navy-900',
    BLUE: 'bg-blue-500 font-medium text-white transition duration-200 hover:bg-blue-600 active:bg-blue-700',
    RED: 'bg-red-500 font-medium text-white transition duration-200 hover:bg-red-600 active:bg-red-700',
    GREEN: 'bg-green-500 font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700',
    PURPLE: 'bg-purple-500 font-medium text-white transition duration-200 hover:bg-purple-600 active:bg-purple-700',
    YELLOW: 'bg-yellow-500 font-medium text-white transition duration-200 hover:bg-yellow-600 active:bg-yellow-700',
    ORANGE: 'bg-orange-500 font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700',
    GHOST: 'bg-none font-medium text-brand-500'
};

const BUTTON_OUTLINE_TYPE = {
    DEFAULT: 'border-2 border-brand-500 font-medium text-brand-500 transition duration-200 hover:bg-brand-800/5 active:bg-brand-800/5',
    SECONDARY: 'border-2 border-gray-300 font-medium text-textDefault transition duration-200 hover:bg-gray-200/10 active:bg-gray-300/10',
    DARK: 'border-2 border-navy-700 font-medium text-textDefault transition duration-200 hover:bg-navy-800/5 active:bg-navy-900/5',
    BLUE: 'border-2 border-blue-500 font-medium text-blue-500 transition duration-200 hover:bg-blue-600/5 active:bg-blue-700/5',
    RED: 'border-2 border-red-500 font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5',
    GREEN: 'border-2 border-green-500 font-medium text-green-500 transition duration-200 hover:bg-green-600/5 active:bg-green-700/5',
    PURPLE: 'border-2 border-purple-500 font-medium text-purple-500 transition duration-200 hover:bg-purple-600/5 active:bg-purple-700/5',
    YELLOW: 'border-2 border-yellow-500 font-medium text-yellow-500 transition duration-200 hover:bg-yellow-600/5 active:bg-yellow-700/5',
    ORANGE: 'border-2 border-orange-500 font-medium text-orange-500 transition duration-200 hover:bg-orange-600/5 active:bg-orange-700/5',
    GHOST: 'border-0 font-medium text-brand-500',
};

const BUTTON_SIZE = {
    xs: 'p-2 text-xs',
    sm: 'px-3 py-2.5 text-sm',
    md: 'px-4 py-3 text-base',
    lg: 'px-5 py-4 text-lg',
    xl: 'px-10 py-8 text-xl',
};

const getButtonSize = (size) => {
    const buttonSize = BUTTON_SIZE[size] ? BUTTON_SIZE[size] : BUTTON_SIZE.md;

    return buttonSize;
}

const getButtonVariant = (variant, isOutline) => {
    let content = BUTTON_TYPE.DEFAULT;
    content = isOutline ? BUTTON_OUTLINE_TYPE[variant] : BUTTON_TYPE[variant];

    return content;
};

export {
    BUTTON_TYPE,
    BUTTON_OUTLINE_TYPE,
    BUTTON_SIZE,
    getButtonSize,
    getButtonVariant,
};