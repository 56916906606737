import { useContext } from "react";
import { ContextSaleReport } from "../context/ContextProvider";
import ModalContainer from "components/Modal";
import moment from "moment";
import Table from "components/Table";
import { DETAIL_TABLE_COLUMN_PAYMENT } from "../enum";

const ModalDetail = () => {
  const {
    detailWallet,
    setDetailWallet,
    query: {
      watch: { start_date, end_date },
    },
    names: { region, customer, sales },
  } = useContext(ContextSaleReport);

  const isOpen = Object.keys(detailWallet ?? {}).length > 0;

  return (
    <ModalContainer
      isOpen={isOpen}
      modalTitle={`Laporan Penjualan - Transfer Bank`}
      onClose={() => {
        setDetailWallet({});
      }}
      hideButton
    >
      <div className="w-[800px]">
        {moment(start_date).format("DD MMM YYYY")} -{" "}
        {moment(end_date).format("DD MMM YYYY")}
        <div className="flex gap-2">
          <span className="mt-2">{customer || "Semua Pelanggan Toko"}</span>
          <span className="mt-2 border-l-2 border-gray-300 pl-2">
            {sales || "Semua Sales"}
          </span>
          <span className="mt-2 border-l-2 border-gray-300 pl-2">
            {region || "Semua Wilayah"}
          </span>
        </div>
        <div className="mt-4">
          <Table
            hidePagination
            columnsData={DETAIL_TABLE_COLUMN_PAYMENT}
            tableData={detailWallet.bank_list}
          />
        </div>
      </div>
    </ModalContainer>
  );
};

export default ModalDetail;
