import DefaultColumn from "components/Table/components/DefaultColumn";
import { MdEdit } from "react-icons/md";
import StatusColumn from "../component/StatusColumn";
import moment from "moment";

const STATUS_ENUM = {
    ACTIVE: 1,
    NON_ACTIVE: 0,
    EXPIRED: 3,
}

const TABLE_COLUMN = (onEdit) => [
    {
        accessorKey: 'promo_name',
        header: 'Nama Promo',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'bonus_type.bonus_type_name',
        header: 'Jenis Promo',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'promo_item_ids',
        header: 'Syarat Pembelian',
        cell: info => {
            const { row: { original: { promo_items } } } = info;
            const productNames = promo_items.map(item => item.product_name).join(', ');
            return <DefaultColumn>{productNames}</DefaultColumn>;
        },
    },
    {
        accessorKey: 'start_at',
        header: 'Masa Berlaku',
        cell: info => <DefaultColumn>{moment(info.getValue()).format('DD-MMM-YYYY')}{moment(info?.cell?.original?.end_at).isValid() ? `- ${moment(info.cell.original?.end_at).format('DD-MMM-YYYY')}` : ''}</DefaultColumn>,
    },
    {
        accessorKey: 'date',
        header: 'Masa Berlaku',
        cell: info => <StatusColumn status={info.row.original.status} />,
    },
    {
        accessorKey: 'action',
        header: 'Aksi',
        cell: info => (
            <div className="flex gap-1">
                <button className="bg-[#BEDBFE] text-[#3A65FF] rounded-lg w-8 h-8 flex justify-center items-center" onClick={() => onEdit(info.cell.row.original.id_promo)}>
                    <MdEdit />
                </button>
            </div>
        ),
    },
];

const DUMMY_DATA = [
    {
        id: 1,
        name: 'Promo Spesial',
        type: 'Voucher',
        terms: 'Susu Bear Brand',
        date: '1 JAN 2024 - 31 JAN 2024',
        status: 1
    },
    {
        id: 2,
        name: 'Promo Spesial',
        type: 'Cashback',
        terms: 'Susu Bear Brand',
        date: '1 JAN 2024 - 31 JAN 2024',
        status: 2
    },
    {
        id: 1,
        name: 'Promo Spesial',
        type: 'Diskon',
        terms: 'Susu Bear Brand',
        date: '1 JAN 2024 - 31 JAN 2024',
        status: 3
    }
]

const DEFAULT_VALUES_FORM = {
    "promo_name" : "",
    "description" : "",
    "banner_path" : "",
    "active_days" : [],
    "id_region" : 0,
    "start_at" : "",
    "end_at" : "",
    "active_hour_start" : "",
    "active_hour_end" : "",
    "is_transaction" : 0,
    "promo_item_ids" : [], ///product id criteria promo if is transaction 0
    "minimum_criteria_type" : 0, /// 1 : payment, 2: qty
    "minimum_criteria_value" : 0,
    "bonus_type" : { /// Voucher, Cashback, Diskon, Giveaway
        "bonus_id" : 0, /// 1 : Voucher, 2 : Cashback, 3 : Diskon, 4 : Giveaway
        "bonus_value" : 0,
        "is_percentage" : 0,
        "bonus_item_ids" : [] // provide if type 4
    }
}

export {
    STATUS_ENUM,
    TABLE_COLUMN,
    DUMMY_DATA,
    DEFAULT_VALUES_FORM,
}