import { AccordionIcon } from "@chakra-ui/accordion";
import DashIcon from "components/icons/DashIcon";

const MenuItem = ({ icon, name, isActive, isHaveChild, showIcon = true }) => {
    return (
        <div className="relative w-full flex hover:cursor-pointer">
            <li
                className="my-[3px] flex cursor-pointer items-center"
            >
                {showIcon && (
                    <span
                        className={`${
                            isActive
                            ? "font-bold text-brand-500 dark:text-white"
                            : "font-medium text-textDefault/50"
                        }`}
                        >
                        {icon ? icon : <DashIcon />}{" "}
                    </span>
                )}
                
                <p
                    className={`leading-1 ml-4 flex ${
                        isActive
                        ? "font-bold text-textDefault dark:text-white"
                        : "font-medium text-textDefault/50"
                    }`}
                >
                    {name}
                </p>
            </li>
            {isHaveChild && (
                <AccordionIcon className='absolute right-6 top-px h-9 w-1 mt-1 !border-0'/>
            )}
            {isActive ? (
                <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500" />
            ) : null}
        </div>   
    );
};
  
export default MenuItem;
