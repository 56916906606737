import { useContext, useEffect } from "react";
import { ContextPurchaseOrder } from "../context/ContextProvider";
import { STATUS_ENUM } from "../config/enum";
import ModalContainer from "components/Modal";
import { useFieldArray, useForm } from "react-hook-form";
import { updatePO } from "apis/purchase_order";
import InputMultipleSelect from "components/Form/InputMultipleSelect";
import InputField from "components/fields/InputField";

const ModalEditStockProduct = () => {
  const { toast, setUpdateState, updateState, fetchPO } =
    useContext(ContextPurchaseOrder);
  const forms = useForm({ defaultValues: {} });

  const array = useFieldArray({
    control: forms.control,
    name: "purchase_order_details",
  });
  const isOpen = Object.keys(updateState ?? {}).length > 0;

  useEffect(() => {
    if (isOpen) forms.reset(updateState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const resetForm = () => {
    setUpdateState({});
    forms.reset({});
  };

  const handleSubmit = forms.handleSubmit(async (values) => {
    try {
      try {
        await updatePO({
          id_purchase_order: values.id_purchase_order,
          id_user: values.user.id_user,
          no_po: values.no_po,
          supplier_name: values.supplier_name,
          po_date: values.po_date,
          id_region: values.id_region,
          id_gudang: values.id_gudang,
          status: values.status,
          total: values.total.toString(),
          other_cost: values.other_cost.toString(),
          po_details: values.purchase_order_details.map((detail) => ({
            id_purchase_order_detail: detail.id_purchase_order_detail,
            id_purchase_order: detail.id_purchase_order,
            id_product: detail.product.id_product,
            qty: detail.qty,
            id_unit: detail.id_unit,
            price: detail.price,
            note: detail.notes || "",
          })),
        });

        toast({
          title: "Sukses edit status",
          status: "success",
          isClosable: true,
          position: "top-center",
        });

        setUpdateState({});
      } catch (err) {
        toast({
          title: err,
          status: "error",
          isClosable: true,
          position: "top-center",
        });
      }

      resetForm();

      fetchPO({ page: 1, limit: 10 });
    } catch (error) {
      toast({
        title: error,
        status: "error",
        isClosable: true,
        position: "top-center",
      });
    }
  });

  const options = Object.keys(STATUS_ENUM).map((st) => {
    let value = { label: "", style: "" };

    switch (STATUS_ENUM[st]) {
      case STATUS_ENUM.DRAFT:
        value = { label: "Draf", style: "bg-[#E5E5E5] text-[#0A1A1B]" };
        break;

      case STATUS_ENUM.PROCESS:
        value = { label: "Diproses", style: "bg-[#FEF2C7] text-[#E99518]" };
        break;

      case STATUS_ENUM.SENT:
        value = { label: "Dikirim", style: "bg-[#D8EAFF] text-[#3965FF]" };
        break;

      case STATUS_ENUM.RECEIVED:
        value = { label: "Selesai", style: "bg-[#EBFAF5] text-[#04C99E]" };
        break;
      case STATUS_ENUM.CANCEL:
        value = { label: "Batal", style: "bg-[#E5E5E5] text-[#0A1A1B]" };
        break;

      default:
        break;
    }

    return {
      label: (
        <div
          className={`rounded-full px-2 py-1 tracking-wide ${value.style} w-fit text-xs`}
        >
          {st}
        </div>
      ),
      value: STATUS_ENUM[st],
    };
  });

  const label = options.find(
    (opt) => opt.value === forms.watch("status")
  )?.label;

  return (
    <ModalContainer
      isOpen={isOpen}
      modalTitle={`Update Stok Purchase Order`}
      onClose={resetForm}
      onSubmit={handleSubmit}
      isSubmitting={forms.formState.isSubmitting}
    >
      <div className="h-[400px] w-[400px]">
        <InputMultipleSelect
          {...(forms.watch("status") ? { initialLabel: label } : {})}
          value={
            options.find((opt) => opt.value === forms.watch("status"))?.value
          }
          fetchList={null}
          onChange={(value) => {
            forms.setValue("status", value);
          }}
          optionList={options}
          hideSearch
        />
        {forms.watch("status") === 4 && (
          <div className="mt-2 flex flex-col gap-4">
            {array.fields.map((po, idx) => (
              <InputField
                type="number"
                label={`Stok Aktual - ${po.product.product_name}`}
                value={po.qty}
                onChange={(e) =>
                  array.update(idx, { ...po, qty: +e.target.value })
                }
              />
            ))}
          </div>
        )}
      </div>
    </ModalContainer>
  );
};

export default ModalEditStockProduct;
