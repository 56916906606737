import Button from "components/Button";
import Text from "components/Text";
import Checkbox from "components/checkbox";
import InputField from "components/fields/InputField";
import { useSignIn } from "./useSignIn";
import { getErrorField } from "helper/geterrorfield";

export default function SignIn() {
  const { forms, handleSubmit } = useSignIn();

  return (
    <div className="mb-16 mt-16 h-full w-full flex-1 items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-textDefault dark:text-white">
          Taraswin Dashboard
        </h4>
        <p className="mb-9 ml-1 text-base text-textDefault/50">
          Silahkan masuk untuk menggunakan aplikasi
        </p>
        <form>
          {/* Email */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email"
            placeholder="Contoh: anandabudiman@gmail.com"
            id="email"
            type="text"
            required
            state={
              getErrorField(forms.formState.errors, "email").show ? "error" : ""
            }
            errors={getErrorField(forms.formState.errors, "email")}
            register={forms.register("email")}
          />

          {/* Password */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Password"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            required
            state={
              getErrorField(forms.formState.errors, "password").show
                ? "error"
                : ""
            }
            errors={getErrorField(forms.formState.errors, "password")}
            register={forms.register("password")}
          />

          {/* <div className="mb-10 flex items-center justify-between">
            <Checkbox label="Tetap masuk" />

            <Text
              customStyle="cursor-pointer"
              variant="linkBold"
              content="Lupa Password?"
            />
          </div> */}

          <Button
            variant="DEFAULT"
            label="Masuk"
            htmlType="submit"
            disabled={forms.formState.isSubmitting}
            customStyle="w-full flex justify-center"
            onClickHandler={handleSubmit}
          />
        </form>
      </div>
    </div>
  );
}
