import TotalSpent from "views/admin/default/components/TotalSpent";
import { MdBarChart, MdShoppingCart } from "react-icons/md";

import Widget from "components/widget/Widget";
import Card from "components/card";
import Text from "components/Text";
import PieChart from "components/charts/PieChart";
import { pieChartOptions, pieChartData } from "variables/charts";
import ColumnChart from "components/charts/ColumnChart";
import { columnChartOption } from "variables/charts";
import { columnChartData } from "variables/charts";
import { currency, numberSeparator } from "helper";
import InputDateRange from "components/Form/InputDateRange";
import { ContextTransaction } from "./context/ContextProvider";
import { useDashboard } from "./context/useDashboard";
import InputMultipleSelect from "components/Form/InputMultipleSelect";
import { getSales } from "apis/sales";
import { getRegion } from "apis/region";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { LayoutContext } from "context/LayoutContext";
import { useToast } from "@chakra-ui/react";
import { getDashboard } from "apis/dashboard";
import { getGudang } from "apis/gudang";

const CHART_DEFAULT = {
  PIE: {
    OPTIONS: ['Item 1', 'Item 2'],
    VALUE: [50, 50]
  },
  LINE: {
    OPTIONS: [
      moment().format("YYYY-MM-DD"),
    ],
    VALUE: [{
      name: 'Penjualan',
      data: [0]
    }]
  },
  COLUMN: {
    OPTIONS: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    VALUE: [{
      name: 'Penjualan',
      data: [0, 0, 0, 0, 0, 0, 0]
    }],
    VALUE_TOTAL: [{
      name: 'Total Transaksi',
      data: [0, 0, 0, 0, 0, 0, 0]
    }]
  },
}

const Dashboard = () => {
  const { open, setOpen, query, setQuery, dashboardData, setDashboardData } = useDashboard();
  const { setHeaderComponent } = useContext(LayoutContext);
  const toast = useToast();
  const [dateRange, setDateRange] = useState([moment().startOf('month').toDate(), moment().endOf('month').toDate()]);
  const [chartData, setChartData] = useState(CHART_DEFAULT)
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    if (startDate && endDate) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        watch: {
          ...prevQuery.watch,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
          page: 1,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getDashboard(query.watch);

        setDashboardData(response);
      } catch (err) {
        toast({
          title: "Gagal",
          description: err,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.watch]);

  useEffect(() => {
    const newPieChart = {
      OPTIONS: dashboardData.sale_per_category ? dashboardData?.sale_per_category.map(item => item.category_name) : chartData.PIE.OPTIONS,
      VALUE: dashboardData.sale_per_category ? dashboardData?.sale_per_category.map(item => item.category_percentage) : chartData.PIE.VALUE,
    }

    const currentMonthDates = Array.from({length: moment().daysInMonth()}, (v, k) => moment().format("YYYY-MM-") + (k+1).toString().padStart(2, '0'));
    const matchedSales = currentMonthDates.map(date => {
      const day = parseInt(date.split('-')[2], 10);
      const found = dashboardData?.group_day_to_day ? dashboardData?.group_day_to_day.find(item => item.dayByDate === day) : 0;
      return found ? found.total_sale : 0;
    });
  
    const newLineChart = {
      OPTIONS: dashboardData.group_day_to_day ? currentMonthDates : chartData.LINE.OPTIONS,
      VALUE: [
        {
          name: 'Penjualan',
          data: dashboardData.group_day_to_day ? matchedSales : chartData.LINE.OPTIONS,
        }
      ]
    }

    const newColumnChart = {
      OPTIONS: dashboardData.group_by_day_name ? dashboardData?.group_by_day_name.map(item => item.day_name) : chartData.COLUMN.OPTIONS,
      VALUE: [{
        name: 'Penjualan',
        data: dashboardData.group_by_day_name ? dashboardData?.group_by_day_name.map(item => item.total_sale) : chartData.COLUMN.VALUE
      }],
      VALUE_TOTAL: [{
        name: 'Transaksi',
        data: dashboardData.group_by_day_name ? dashboardData?.group_by_day_name.map(item => item.total_transaction) : chartData.COLUMN.VALUE_TOTAL
      }],
    }

    setChartData({
      ...chartData,
      PIE: {...newPieChart},
      LINE: {...newLineChart},
      COLUMN: {...newColumnChart},
    })
  }, [dashboardData])

  useEffect(() => {
    setHeaderComponent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContextTransaction.Provider
      value={{
        open,
        setOpen,
        query,
        setQuery,
        dashboardData,
        chartData,
        setChartData,
      }}
    >
      <div>
        <div className="mt-3 flex gap-5">
          <div className="w-1/3">
            <InputMultipleSelect
              fetchList={getSales}
              keyLabel="sales_name"
              keyValue="id_sales"
              initialLabel="Semua Sales"
              onChange={(value) =>
                setQuery((prevQuery) => ({
                  ...prevQuery,
                  watch: { ...prevQuery.watch, id_sales: value, page: 1 },
                }))
              }
              value={query.watch.id_sales}
              rounded
            />
          </div>
          <div className="w-1/3">
            <InputMultipleSelect
              fetchList={getGudang}
              noLimit
              keyLabel="gudang_name"
              keyValue="id_gudang"
              initialLabel="Semua Gudang"
              onChange={(value) =>
                setQuery((prevQuery) => ({
                  ...prevQuery,
                  watch: { ...prevQuery.watch, id_gudang: value, page: 1 },
                }))
              }
              value={query.watch.id_gudang}
              rounded
            />
          </div>
          <div className="w-1/3">
            <InputDateRange
              onChange={(update) => setDateRange(update)}
              rounded
              value={[startDate, endDate]}
              className="h-[48px] w-full"
            />
          </div>
        </div>

          {/* Card widget */}
        <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
          <Widget
            icon={<MdShoppingCart className="h-7 w-7" />}
            title={"Penjualan Kotor"}
            subtitle={currency(dashboardData?.penjualan_kotor || 0)}
          />
          <Widget
            icon={<MdBarChart className="h-6 w-6" />}
            title={"Penjualan Bersih"}
            subtitle={currency(dashboardData?.penjualan_bersih || 0)}
          />
          <Widget
            icon={<MdBarChart className="h-7 w-7" />}
            title={"Laba Kotor"}
            subtitle={currency(dashboardData?.laba_kotor || 0)}
          />
          <Widget
            icon={<MdBarChart className="h-6 w-6" />}
            title={"Jumlah Transaksi"}
            subtitle={numberSeparator(dashboardData?.transaction_count || 0)}
          />
          <Widget
            icon={<MdBarChart className="h-7 w-7" />}
            title={"Rata-Rata Penjualan per Transaksi"}
            subtitle={numberSeparator(dashboardData?.average_sales || 0).replace(/\./g, ',').replace(/,.*$/g, '')}
          />
          <Widget
            icon={<MdBarChart className="h-6 w-6" />}
            title={"Laba Kotor"}
            subtitle={currency(dashboardData?.laba_kotor || 0)}
          />
        </div>

        {/* Charts */}

        <div className="my-5 grid grid-cols-1 gap-5 md:grid-cols-1">
          <TotalSpent />
        </div>

        <div className="my-5 grid grid-cols-2 gap-5 md:grid-cols-2">
          <Card extra="!p-[20px]">
            <div className="relative flex items-center justify-between">
              <Text variant="h1" content="Product Terlaris" />
            </div>

            <div className="flex h-[354px] w-full flex-col gap-5 mt-5">
              {dashboardData?.top_10_product?.map((product) => (
                <div className="flex gap-2">
                  <div className="w-1/2">
                    <Text variant="sm" content={product.product_name} />
                  </div>
                  <div className="w-1/2 text-end">
                    <Text variant="sm" content={numberSeparator(product.quantity_count)} />
                  </div>
                </div>
              ))}
            </div>
          </Card>

          <Card extra="!p-[20px]">
            <div className="relative flex items-center justify-between">
              <Text variant="h1" content="Penjualan per Kategori" />
            </div>

            <div className="flex h-full w-full flex-col gap-5 mt-5">
              <PieChart options={pieChartOptions(chartData.PIE.OPTIONS)} series={chartData.PIE.VALUE} />
            </div>
          </Card>
        </div>

        <div className="my-5 flex gap-5">
          <Card extra="!p-[20px] w-5/12">
            <div className="relative flex items-center justify-between">
              <Text variant="h1" content="Total Transaksi per Hari" />
            </div>

            <div className="flex h-[354px] w-full flex-col gap-5 mt-5">
              <ColumnChart  options={columnChartOption(chartData.COLUMN.OPTIONS, 'transaction')} series={chartData.COLUMN.VALUE_TOTAL} />
            </div>
          </Card>

          <Card extra="!p-[20px] w-7/12">
            <div className="relative flex items-center justify-between">
              <Text variant="h1" content="Penjualan Kotor per Hari" />
            </div>

            <div className="flex h-[354px] w-full flex-col gap-5 mt-5">
              <ColumnChart  options={columnChartOption(chartData.COLUMN.OPTIONS)} series={chartData.COLUMN.VALUE} />
            </div>
          </Card>
        </div>
      </div>
    </ContextTransaction.Provider>
  );
};

export default Dashboard;
