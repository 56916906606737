const INPUT_SIZE = {
    xs: 'p-2 text-xs',
    sm: 'px-3 py-2.5 text-sm',
    md: 'px-4 py-3 text-base',
    lg: 'px-5 py-4 text-lg',
    xl: 'px-10 py-8 text-xl',
};

const getInputSize = (size) => {
    const buttonSize = INPUT_SIZE[size] ? INPUT_SIZE[size] : INPUT_SIZE.md;

    return buttonSize;
}

export {
    INPUT_SIZE,
    getInputSize,
};