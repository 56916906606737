export const optionPromoType = [
    {
        label: 'Semua Jenis Promo',
        value: '',
    },
    {
        label: 'Voucher',
        value: 1,
    },
    {
        label: 'Cashback',
        value: 2,
    },
    {
        label: 'Diskon',
        value: 3,
    },
    {
        label: 'Giveaway',
        value: 4,
    },
];

export const optionStatus = [
    {
        label: 'Semua Status',
        value: '',
    },
    {
        label: 'Aktif',
        value: 1,
    },
    {
        label: 'Tidak Aktif',
        value: 0,
    },
];

export const optionDays = {
    section1: [
        { label: 'Semua Hari', value: 0 },
        { label: 'Selasa', value: 2 },
        { label: 'Kamis', value: 4 },
        { label: 'Sabtu', value: 6 },
        
    ],
    section2: [
        { label: 'Senin', value: 1 },
        { label: 'Rabu', value: 3 },
        { label: 'Jumat', value: 5 },
        { label: 'Minggu', value: 7 }
    ],
};

export const optionBonusType = [
    { value: 1, label: 'Voucher' },
    { value: 2, label: 'Cashback' },
    { value: 3, label: 'Diskon' },
    { value: 4, label: 'Giveaway' },
];

export const optionBonusTypeResult = [
    { value: 'nominal', label: 'Rp' },
    { value: 'percent', label: '%' },
]