import { useState } from "react";

export const useTransactionList = () => {
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [query, setQuery] = useState({
    watch: {
      keyword: "",
      id_sales: "",
      id_gudang: "",
      page: 1,
      limit: 10,
      start_date: "",
      end_date: "",
      is_paid: null,
    },
    total_page: 0,
    total_data: 0,
    total_value: 0,
    unpaid_value: 0,
    paid_value: 0,
  });

  return {
    open,
    setOpen,
    query,
    setQuery,
    tableData,
    setTableData,
  };
};
