import ActionColumn from "components/Table/components/ActionColumn";
import DefaultColumn from "components/Table/components/DefaultColumn";
import StatusColumn from "components/Table/components/StatusColumn";
import { currency } from "helper";

const TableColumn = (onEdit, onDelete) => [
  {
    accessorKey: "product_name",
    header: "Nama Produk",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
  },
  {
    accessorKey: "sku",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    header: "SKU",
  },
  {
    accessorKey: "category.category_name",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    header: "Kategori",
  },
  {
    accessorKey: "capital_price",
    cell: (info) => <DefaultColumn>{currency(info.getValue())}</DefaultColumn>,
    header: "Harga Beli",
  },
  {
    accessorKey: "selling_price",
    cell: (info) => <DefaultColumn>{currency(info.getValue())}</DefaultColumn>,
    header: "Harga Jual",
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: (info) => <StatusColumn status={info.getValue()} />,
  },
  {
    accessorKey: "action",
    header: "Aksi",
    size: 125,
    cell: (info) => (
      <ActionColumn onClickEdit={onEdit} onClickDelete={onDelete} {...info} />
    ),
    enableSorting: false,
  },
];

export { TableColumn };
