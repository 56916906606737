import { MdCreate, MdDelete, MdRemoveRedEye } from "react-icons/md";

const ActionColumn = (props) => {
  const {
    row: { original },
    onClickEdit,
    onClickDelete,
    onClickDetail = null,
  } = props;

  return (
    <div className="flex gap-1">
      {onClickDetail instanceof Function && (
        <button
          className="flex h-8 w-8 items-center justify-center rounded-lg bg-[#EBFAF0] text-[#215759]"
          onClick={() => onClickDetail(original)}
        >
          <MdRemoveRedEye />
        </button>
      )}
      <button
        className="flex h-8 w-8 items-center justify-center rounded-lg bg-[#BEDBFE] text-[#3A65FF]"
        onClick={() => onClickEdit(original)}
      >
        <MdCreate />
      </button>
      <button
        className="flex h-8 w-8 items-center justify-center rounded-lg bg-[#FECACA] text-[#E31B1A]"
        onClick={() => onClickDelete(original)}
      >
        <MdDelete />
      </button>
    </div>
  );
};

export default ActionColumn;
