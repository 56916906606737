import { useState } from "react";

export const useDashboard = () => {
  const [open, setOpen] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [query, setQuery] = useState({
    watch: {
      id_gudang: "",
      id_sales: "",
      start_date: "",
      end_date: "",
    },
  });

  return {
    open,
    setOpen,
    query,
    setQuery,
    dashboardData,
    setDashboardData,
  };
};
