import httpService from "services/api.service";

export const createProductCategory = async (payload) => {
  try {
    const res = await httpService.post("/category", payload);

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};

export const updateProductCategory = async (payload) => {
  try {
    const res = await httpService.put(`/category/${payload.id}`, {
      category_name: payload.category_name,
      status: payload.status,
    });

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};

export const deleteProductCategory = async (payload) => {
  try {
    const res = await httpService.put(`/delete_category/${payload.id}`);

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};

export const getProductCategory = async (params) => {
  try {
    const res = await httpService.get("/category", { params });

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};
