import { useState } from "react";
import { SALES_TYPE } from "../enum";

export const useSalesReport = () => {
  const [detailWallet, setDetailWallet] = useState({});
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({
    watch: {
      keyword: "",
      page: 1,
      limit: 10,
      start_date: "",
      end_date: "",
      id_sales: "",
      id_customer: "",
      id_gudang: "",
      type: SALES_TYPE.PRODUCT,
    },
    total_page: 0,
    total_data: 0,
  });

  const [names, setNames] = useState({ customer: "", sales: "", gudang: "" });

  return {
    detailWallet,
    setDetailWallet,
    query,
    setQuery,
    tableData,
    setTableData,
    loading,
    setLoading,
    names,
    setNames,
  };
};
