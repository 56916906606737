// Custom components
import Text from "components/Text";
import React from "react";

import { getInputSize } from "../utils";

const InputCurrency = (props) => {
    const { label, id, extra, placeholder, size = 'md', rounded = false, state, disabled, register, hideCurrency = false } = props;
    const roundedSize = size === 'sm' || size === 'xs' ? 'rounded-lg' : 'rounded-xl';
    const styleDisabled = '!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]';
    const styleError = "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400";
    const styleSuccess = "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400";

    return (
        <div className={`${extra} flex flex-col gap-1`}>
            {label && (
                <Text variant="linkBold" content={label} />
            )}
            
            <div class="relative mb-4 flex items-stretch">
                {hideCurrency ? null : (<span
                    className={`flex ${disabled ? styleDisabled : state === 'error' ? styleError : state === 'success' ? styleSuccess : ''} items-center ${rounded ? 'rounded-full' : roundedSize} whitespace-nowrap rounded-e border border-e-0 border-solid border-neutral-200 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-surface dark:border-white/10 dark:text-white`}
                    id="basic-addon1"
                >
                    Rp
                </span>)}
                <input
                    disabled={disabled}
                    type="number"
                    id={id}
                    placeholder={placeholder}
                    className={`flex ${rounded ? 'rounded-full' : roundedSize} border-s-0 rounded-s ${getInputSize(size)} w-full items-center justify-center rounded-xl border bg-white/0 outline-none ${
                        disabled === true
                            ? styleDisabled
                            : state === "error"
                            ? styleError
                            : state === "success"
                            ? styleSuccess
                            : "border-gray-200 dark:!border-white/10 dark:text-white"
                        }`}
                    {...register}
                />
            </div>

            
        </div>
    );
}

export default InputCurrency;
