import { requestLogin } from "apis/auth";
import { useForm } from "react-hook-form";
import { useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { validations } from "./utils";
import { useNavigate } from "react-router-dom";

export const useSignIn = () => {
  const forms = useForm({
    defaultValues: { email: "", password: "" },
    resolver: yupResolver(validations),
  });
  const toast = useToast();
  const navigate = useNavigate()

  const handleSubmit = forms.handleSubmit(async (values) => {
    try {
      await requestLogin(JSON.stringify(values));

      navigate("/admin/dashboard");
    } catch (error) {
      toast({
        title: error,
        status: "error",
        isClosable: true,
        position: "top-center",
      });
    }
  });

  return {
    forms,
    handleSubmit,
  };
};
