import React, { useState } from "react";
import Dropdown from "components/dropdown";
import Text from "components/Text";
import { useToast } from "@chakra-ui/react";
import { requestLogout } from "apis/auth";
import { useNavigate } from "react-router-dom";
import { getUser } from "helper/localstorage";
import { HiOutlineUserCircle } from "react-icons/hi";

const Navbar = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <nav className="sticky top-0 z-[1] flex flex-row flex-wrap items-center justify-end bg-white shadow-xl shadow-shadow-500">
      <div className="w-fit-content mt-[3px] flex h-[61px] items-center gap-4 px-8">
        <Dropdown
          button={
            <div className="flex cursor-pointer items-center gap-2">
              <HiOutlineUserCircle style={{ width: '20px', height: '20px'}} />
              <Text variant="link" content={getUser()?.name || ''} />
            </div>
          }
          children={
            <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">

              <div className="flex flex-col p-4">
                <a
                  href="#!"
                  onClick={async () => {
                    try {
                      setLoading(true);
                      await requestLogout();

                      navigate("/auth/sign-in");
                      setLoading(false);
                    } catch (error) {
                      toast({
                        title: error,
                        status: "error",
                        isClosable: true,
                        position: "top-center",
                      });
                    }
                  }}
                  className={`text-sm font-medium ${
                    loading ? "text-textDefault" : "text-red-500"
                  } transition duration-150 ease-out hover:ease-in`}
                >
                  {loading ? "Loading..." : "Log Out"}
                </a>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
    </nav>
  );
};

export default Navbar;
