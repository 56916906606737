import { TRANSACTION_TYPE } from '../config/enum';

const StatusColumn = ({ status }) => {
    let value = { label: '', style: '' };

    switch (status) {
        case TRANSACTION_TYPE.IN:
            value = { label: 'Terbayar', style: 'bg-[#F1F3F3] text-[#215759]' };
            break;

        case TRANSACTION_TYPE.OUT:
            value = { label: 'Belum Bayar', style: 'bg-[#FECACA] text-[#E31A1A]' };
            break;

        default:
            break;
    }

    return (
        <div
            className={`rounded-full py-1 px-2 tracking-wide ${value.style} whitespace-nowrap w-fit h-fit text-xs`}
        >
            {value.label}
        </div>
    );
};

export default StatusColumn;
