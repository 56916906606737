import { getInputSize } from '../utils';
import Text from 'components/Text';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

const InputTimepicker = ({
    label = '',
    size = 'md',
    value = new Date(),
    onChange = () => {},
    rounded,
    className
}) => {
    const roundedSize =
        size === 'sm' || size === 'xs' ? 'rounded-lg' : 'rounded-xl';

    return (
        <div className="flex flex-col gap-1">
            {label && <Text variant="linkBold" content={label} />}
            <ReactDatePicker
                showIcon
                className={`${
                    rounded ? 'rounded-full' : roundedSize
                } border bg-white ${getInputSize(size)} ${className}`}
                selected={value}
                showTimeSelect
                showTimeSelectOnly
                onChange={(date) => onChange(date)}
                dateFormat="h:mm aa"
            />
        </div>
    );
};

export default InputTimepicker;
