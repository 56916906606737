import Table from "components/Table";
import Card from "components/card";
import { TABLE_COLUMN } from "./enum";
import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutContext } from "context/LayoutContext";
import InputSearch from "components/Form/InputSearch";
import { getInventory } from "apis/inventory";
import InputMultipleSelect from "components/Form/InputMultipleSelect";
import { getGudang } from "apis/gudang";
import { getSales } from "apis/sales";
import { getCustomers } from "apis/customers";
import { debounce, removeEmptyValues } from "helper/index";
import { getInventoryExport } from "apis/export";
import Button from "components/Button";
import { MdOutlineFileUpload } from "react-icons/md";
import { dataRawUser, getUser } from "helper/localstorage";

const Inventori = () => {
  const { setHeaderComponent } = useContext(LayoutContext);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState({
    watch: { id_gudang: "", id_sales: "", id_customer: "", keyword: "", page: 1, limit: 10 },
  });

  console.log(dataRawUser())

  useEffect(() => {
    (async () => {
      try {
        const response = await getInventory(removeEmptyValues(query.watch));

        setData(response.data ?? []);
        setQuery((prev) => ({
          ...prev,
          total_data: response.total,
          total_page: response.last_page,
        }));
      } catch (err) {}
    })();
  }, [query.watch]);

  const exportWithQuery = useCallback(() => {
    return getInventoryExport({
      keyword: query.watch.keyword,
      id_gudang: query.watch.id_gudang,
    });
  }, [query.watch.id_gudang, query.watch.keyword]);

  useEffect(() => {
    setHeaderComponent(
      <div className="flex h-full items-end justify-end gap-3">
        <Button
          onClickHandler={() => exportWithQuery()}
          label="Export Excel"
          variant="GHOST"
          size="sm"
          leftIcon={<MdOutlineFileUpload />}
        />
      </div>
    );
  }, [exportWithQuery]);

  return (
    <div>
      <div className="mt-5">
        <Card extra={"w-full h-full p-6 sm:overflow-x-auto"}>
          <div className="flex gap-5">
            <div className="w-full">
              <InputSearch
                rounded
                changeHandler={debounce(
                  (e) =>
                    setQuery((prev) => ({
                      ...prev,
                      watch: { ...prev.watch, keyword: e.target.value },
                    })),
                  1000
                )}
              />
            </div>
          </div>

          <div className="mt-5 flex gap-5">
            <div className="w-1/3">
              <InputMultipleSelect
                fetchList={getCustomers}
                keyLabel="customer_name"
                keyValue="id_customer"
                initialLabel="Semua Pelanggan/Toko"
                onChange={(value) => {
                  setQuery((prevQuery) => ({
                    ...prevQuery,
                    watch: { ...prevQuery.watch, id_customer: value },
                  }));
                }}
                value={query.watch.id_customer}
                rounded
              />
            </div>
            <div className="w-1/3">
              <InputMultipleSelect
                fetchList={getSales}
                keyLabel="sales_name"
                keyValue="id_sales"
                initialLabel="Semua Sales"
                onChange={(value) => {
                  setQuery((prevQuery) => ({
                    ...prevQuery,
                    watch: { ...prevQuery.watch, id_sales: value },
                  }));
                }}
                value={query.watch.id_sales}
                rounded
              />
            </div>
            <div className="w-1/3">
              <InputMultipleSelect
                fetchList={getGudang}
                noLimit
                keyLabel="gudang_name"
                keyValue="id_gudang"
                initialLabel="Semua Gudang"
                onChange={(value) => {
                  setQuery((prevQuery) => ({
                    ...prevQuery,
                    watch: { ...prevQuery.watch, id_gudang: value },
                  }));
                }}
                value={query.watch.id_gudang}
                rounded
              />
            </div>
          </div>

          <div className="mt-8 h-full overflow-x-scroll xl:overflow-hidden">
            <Table
              columnsData={TABLE_COLUMN}
              tableData={data}
              paginationCallback={(page, offset) => {
                setQuery((prevQuery) => ({
                  ...prevQuery,
                  watch: { ...prevQuery.watch, page, limit: offset },
                }));
              }}
              totalData={query.total_data}
              currentPage={query.watch.page}
              perPage={query.watch.limit}
              pageCount={query.total_page}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Inventori;
