import Table from "components/Table";
import Card from "components/card";
import { TABLE_COLUMN, validations } from "./enum";
import Button from "components/Button";
import { MdAdd, MdOutlineFileUpload } from "react-icons/md";
import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutContext } from "context/LayoutContext";
import InputSelect from "components/Form/InputSelect";
import InputMultipleSelect from "components/Form/InputMultipleSelect";
import InputSearch from "components/Form/InputSearch";
import ModalContainer from "components/Modal";
import InputText from "components/Form/InputText";
import InputUpload from "components/Form/InputUpload";
import Text from "components/Text";
import { createCustomer, deleteCustomer, getCustomers, updateCustomer } from "apis/customers";
import { useToast } from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import { getSales } from "apis/sales";
import { getLocations } from "apis/locations";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getErrorField } from "helper/geterrorfield";
import Confirmation from "components/Modal/Confirmation";
import { debounce } from "helper/index";
import { getCustomerExport } from "apis/export";

const Pelanggan = () => {
    const { setHeaderComponent } = useContext(LayoutContext);
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
    const [formType, setFormType] = useState('create');
    const [locationList, setLocationList] = useState([]);
    const [salesList, setSalesList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [deleteData, setDeleteData] = useState({});
    const [table, setTable] = useState({
        loading: false,
        pagination: { total: 1, current_page: 1, per_page: 10 },
    });
    const [query, setQuery] = useState({ keyword: ''})

    const defaultFieldValues = {
        id_customer: '',
        customer_name: "",
        address: "",
        status: '1',
        id_toko: "",
        id_sales: '',
        no_hp: '',
        note: "",
        id_region: '',
        image_url: [],
        customer_type: '1',
    }

    const forms = useForm({
        defaultValues: defaultFieldValues,
        resolver: yupResolver(validations),
    });

    const toast = useToast();

    const SELECT_DATA = [
        {
            label: 'Semua Status',
            value: 0,
        },
        {
            label: 'Aktif',
            value: 1,
        },
        {
            label: 'Tidak Aktif',
            value: 2,
        },
    ];

    const onTableChange = (tableState) => {
        console.log(tableState)
    }

    const fetchLocations = async () => {
        try {
          const res = await getLocations({ limit: 20, page: 1 })
  
          const formattedData = res.map(item => ({ value: item.id_region.toString(), label: item.region_name }))
          setLocationList([{ value: '', label: 'Pilih Area' }, ...formattedData])
        } catch (error) {
          toast({
            title: 'Gagal mendapatkan data lokasi',
            status: "error",
            isClosable: true,
            position: "top-center",
          });
        }
    }

    const fetchSales = async () => {
        try {
          const res = await getSales({ limit: 20, page: 1 })
          const formattedData = res.data.map(item => ({ value: item.id_sales.toString(), label: item.sales_name }))
          setSalesList([{ value: '', label: 'Pilih Sales' }, ...formattedData])
        } catch (error) {
          toast({
            title: 'Gagal mendapatkan data sales',
            status: "error",
            isClosable: true,
            position: "top-center",
          });
        }
      }

    const fetchCustomers = async (params = {}) => {
        try {
          const res = await getCustomers(params)
          setCustomerList(res.data)
          setTable((prev) => ({
            ...prev,
            pagination: {
              total: res.last_page,
              per_page: res.per_page,
              current_page: res.current_page,
            },
          }));
        } catch (error) {
          toast({
            title: 'Gagal mendapatkan data pelanggan',
            status: "error",
            isClosable: true,
            position: "top-center",
          });
        }
    }

    const openEdit = (val) => {
        forms.setValue('id_customer', val.id_customer);
        forms.setValue('customer_name', val.customer_name);
        forms.setValue('address', val.address);
        forms.setValue('id_toko', val.id_toko.toString());
        forms.setValue('id_sales', val.user_sale.id_sales.toString());
        forms.setValue('no_hp', val.no_hp);
        forms.setValue('note', val.note);
        forms.setValue('id_region', val.region.id_region.toString());
        forms.setValue('image_url', val.image_url);
        forms.setValue('customer_type', val?.customer_type);
        setFormType('edit')
        setIsOpenForm(true)
    }

    const openDelete = (val) => {
        setDeleteData(val)
        setIsOpenConfirmation(true)
    }

    const handleSubmit = forms.handleSubmit(async (values) => {
        try {
          const payload = {
            ...formType === 'edit' && { id_customer: values.id_customer },
            customer_name: values.customer_name,
            address: values.address,
            status: '1',
            id_sales: parseInt(values.id_sales),
            no_hp: parseInt(values.no_hp),
            note: values.note,
            id_region: parseInt(values.id_region),
            image_url: values.image_url,
            id_toko: values.id_toko,
            customer_type: values.customer_type,
          }
  
          if (formType === 'create') {
            await createCustomer(payload);
          } else {
            await updateCustomer(payload);
          }
  
          setIsOpenForm(false);
  
          forms.reset(defaultFieldValues);
  
          fetchCustomers({ page: 1, limit: 10 });
        } catch (error) {
          toast({
            title: error,
            status: "error",
            isClosable: true,
            position: "top-center",
          });
        }
    });

    const handleDelete = async () => {
        try {
          const payload = { id_customer: deleteData.id_customer }
  
          await deleteCustomer(payload);
  
          setIsOpenConfirmation(false);
          setDeleteData({});
  
          fetchCustomers({ page: 1, limit: 10 });
        } catch (error) {
          toast({
            title: error,
            status: "error",
            isClosable: true,
            position: "top-center",
          });
        }
    }

    useEffect(() => {
        fetchLocations()
        fetchSales()
    }, []);

    const exportWithQuery = useCallback(() => {
        return getCustomerExport({
            keyword: query.keyword,
        });
    }, [query.keyword]);

    useEffect(() => {
        setHeaderComponent(
            <div className="flex items-end h-full justify-end gap-3">
                    <Button
                        onClickHandler={() => exportWithQuery()}
                        label="Export Excel"
                        variant="GHOST"
                        size="sm"
                        leftIcon={<MdOutlineFileUpload />}
                    />
                <Button label="Tambah Pelanggan" variant="DEFAULT" size="sm" leftIcon={<MdAdd />} onClickHandler={() => { forms.reset(defaultFieldValues); setFormType('create'); setIsOpenForm(true); }} />
            </div>
        );
    }, [exportWithQuery])

    useEffect(() => {
        fetchCustomers({ page: 1, limit: 10, keyword: query.keyword })
    }, [query])

    return (
        <Card extra={"w-full h-full p-6 sm:overflow-x-auto flex-grow mt-5 flex flex-col"}>
            <div className="relative flex items-center gap-3">
                <div className="flex-grow">
                    <InputSearch rounded changeHandler={debounce(e => setQuery(prev => ({ ...prev, keyword: e.target.value })), 1000)} />    
                </div>
                <div className="w-1/5">
                    <InputSelect optionList={SELECT_DATA} rounded />
                </div>
            </div>

            <div className="mt-8 flex flex-grow overflow-x-scroll xl:overflow-hidden">
                <Table
                    columnsData={TABLE_COLUMN(openEdit, openDelete)}
                    tableData={customerList}
                    onTableChange={onTableChange}
                    paginationCallback={(page, offset) =>
                        fetchCustomers({ page, limit: offset })
                    }
                    currentPage={table.pagination.current_page}
                    perPage={table.pagination.per_page}
                    pageCount={table.pagination.total}
                />
            </div>
            <ModalContainer
                isOpen={isOpenForm}
                modalTitle={`${formType === 'create' ? 'Tambah' : 'Edit'} Toko/Pelanggan`}
                onClose={() => { setIsOpenForm(false) }}
                onSubmit={handleSubmit}
            >
                <div className="flex flex-col gap-4 w-[800px] pr-2">
                    <div className="flex gap-4">
                        <div className="w-1/2">
                            <InputField
                                label="Kode Toko/Pelanggan"
                                register={forms.register("id_toko")}
                                state={
                                    getErrorField(forms.formState.errors, "id_toko").show ? "error" : ""
                                }
                                errors={getErrorField(forms.formState.errors, "id_toko")}
                            />
                        </div>
                        <div className="w-1/2">
                            <InputField
                                label="Nama Toko/Pelanggan"
                                register={forms.register("customer_name")}
                                state={
                                    getErrorField(forms.formState.errors, "customer_name").show ? "error" : ""
                                }
                                errors={getErrorField(forms.formState.errors, "customer_name")}
                            />
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <div className="w-1/2">
                            <InputSelect
                                optionList={locationList}
                                label="Area/Kota"
                                register={forms.register("id_region")}
                                onChange={(e) =>
                                    forms.setValue("id_region", e.target.value.toString())
                                }
                                state={
                                    getErrorField(forms.formState.errors, "id_region").show ? "error" : ""
                                }
                                errors={getErrorField(forms.formState.errors, "id_region")}
                            />
                        </div>
                        <div className="w-1/2">
                            <InputSelect
                                optionList={salesList}
                                label="Sales"
                                register={forms.register("id_sales")}
                                onChange={(e) =>
                                    forms.setValue("id_sales", e.target.value.toString())
                                }
                                state={
                                    getErrorField(forms.formState.errors, "id_sales").show ? "error" : ""
                                }
                                errors={getErrorField(forms.formState.errors, "id_sales")}
                            />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-1/2">
                            <InputSelect
                                optionList={[
                                    { label: 'Grosir', value: '2'},
                                    { label: 'Retail', value: '1'},
                                ]}
                                label="Tipe Pelanggan"
                                register={forms.register("customer_type")}
                                onChange={(e) =>
                                    forms.setValue("customer_type", e.target.value.toString())
                                }
                                state={
                                    getErrorField(forms.formState.errors, "customer_type").show ? "error" : ""
                                }
                                errors={getErrorField(forms.formState.errors, "customer_type")}
                            />
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <div className="w-full">
                            <InputField
                                label="Alamat"
                                register={forms.register("address")}
                                state={
                                    getErrorField(forms.formState.errors, "address").show ? "error" : ""
                                }
                                errors={getErrorField(forms.formState.errors, "address")}
                            />
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <div className="w-1/2">
                            <InputField
                                label="No Ponsel"
                                register={forms.register("no_hp")}
                                state={
                                    getErrorField(forms.formState.errors, "no_hp").show ? "error" : ""
                                }
                                errors={getErrorField(forms.formState.errors, "no_hp")}
                            />
                        </div>
                        <div className="w-1/2">
                            <InputField
                                label="Catatan"
                                register={forms.register("note")}
                                state={
                                    getErrorField(forms.formState.errors, "note").show ? "error" : ""
                                }
                                errors={getErrorField(forms.formState.errors, "note")}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-3">
                        <Text variant="linkBold" content="Foto Toko" />
                        <div className="flex gap-3">
                            {[1, 2, 3, 4, 5].map((v, index) => (
                                <div key={v} className="aspect-square w-1/6">
                                    <InputUpload
                                        accept="image/*"
                                        setValue={(data) =>
                                          forms.setValue(`image_url.${index}`, data[0].url)
                                        }
                                        image={forms.watch(`image_url.${index}`, "")}
                                    />
                                </div>
                            ))}
                        </div>
                        
                    </div>
                </div>
            </ModalContainer>
            <Confirmation
                isOpen={isOpenConfirmation}
                modalTitle={`Konfirmasi Hapus`}
                onClose={() => { setIsOpenConfirmation(false) }}
                onSubmit={handleDelete}
            >
                <div className="flex flex-col gap-4 w-[480px] overflow-y-auto max-h-96 pr-2">
                    <div>
                        Menghapus Pelanggan <b>{deleteData.customer_name}</b> akan menghilangkan data sales secara permanen dari daftar pelanggan.
                    </div>
                </div>
            </Confirmation>
        </Card>
    );
};

export default Pelanggan;
