import InputField from "components/fields/InputField";
import InputSelect from "components/Form/InputSelect";
import { getErrorField } from "helper/geterrorfield";
import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { ContextPurchaseOrder } from "../context/ContextProvider";
import InputDatepicker from "components/Form/InputDatepicker";
import Button from "components/Button";
import { TABLE_COLUMN_PRODUCT_SELECTED } from "../config/enum";
import Table from "components/Table";
import { currency } from "helper/index";
import Text from "components/Text";
import InputMultipleSelect from "components/Form/InputMultipleSelect";
import { getGudang } from "apis/gudang";

const Form = () => {
  const { setOpenProduct, locationList, isEdit, isDetail, setProductList } =
    useContext(ContextPurchaseOrder);
  const forms = useFormContext();

  const handleDelete = (selectedItem) => {
    const deletedData = forms
      .watch("po_details")
      .filter((item) => item.id_product !== selectedItem.id_product);

    forms.setValue("po_details", deletedData);
  };

  const handleChangeData = (type, val, id_product) => {
    const changedData = forms.watch("po_details").map((item) => {
      return {
        ...item,
        ...(item.id_product === id_product && { [type]: val }),
      };
    });

    forms.setValue("po_details", changedData);
  };

  return (
    <div>
      <div className="mt-5 grid grid-cols-5 gap-4">
        <InputField
          label="Pembuat PO"
          register={forms.register("created_by")}
          state={
            getErrorField(forms.formState.errors, "created_by").show
              ? "error"
              : ""
          }
          errors={getErrorField(forms.formState.errors, "created_by")}
          disabled
        />
        <InputField
          label="Pemasok"
          register={forms.register("supplier_name")}
          placeholder="Contoh: Ananda Budiman"
          state={
            getErrorField(forms.formState.errors, "supplier_name").show
              ? "error"
              : ""
          }
          disabled={isEdit || isDetail}
          errors={getErrorField(forms.formState.errors, "supplier_name")}
        />
        <InputField
          label="Nomor Purchase Order"
          register={forms.register("po_no")}
          state={
            getErrorField(forms.formState.errors, "po_no").show ? "error" : ""
          }
          errors={getErrorField(forms.formState.errors, "po_no")}
          disabled
        />
       <InputMultipleSelect
          fetchList={getGudang}
          keyLabel="gudang_name"
          required
          noLimit
          label="Gudang"
          keyValue="id_gudang"
          onChange={(value) => {
            forms.setValue("id_gudang", value);
          }}
          value={forms.watch("id_gudang")}
          errors={getErrorField(forms.formState.errors, "id_gudang")}
          disabled={isDetail}
        />

        <InputDatepicker
          label="Tanggal Transfer Stok"
          className="h-[50px] w-full"
          disabled={isEdit || isDetail}
          onChange={e => forms.setValue('po_date', e)}
          value={forms.watch("po_date")}
        />
      </div>
      <div className="my-3 mr-2 border-b-[1px] border-[#E4E5E8]"></div>
      <div className="mb-3 mr-2 flex items-center justify-between">
        <div className="flex flex-col gap-1">
          <Text variant="h3" content="Rincian Produk" />
          <Text
            variant="p"
            content={`${forms.watch("po_details")?.length} Produk Dipilih`}
          />
        </div>
        <div className="w-1/7 flex">
          <Button
            label="Atur Produk"
            variant="SECONDARY"
            disabled={!forms.watch("id_gudang") || isDetail || isEdit}
            onClickHandler={() => { setOpenProduct(true); setProductList([]); }}
            customStyle="w-full justify-center h-[40px]"
            size="sm"
          />
        </div>
      </div>
      <Table
        columnsData={TABLE_COLUMN_PRODUCT_SELECTED(
          handleDelete,
          handleChangeData,
          { isDetail, isEdit }
        )}
        tableData={forms.watch("po_details")}
        hidePagination
      />
      <div className="mt-5 flex flex-col items-end justify-end pr-5">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col">
            <span
              className={`text-sm font-bold text-textDefault dark:text-white`}
            >
              Subtotal
            </span>
            <span>
              {currency(
                forms
                  .watch("po_details")
                  .reduce(
                    (prev, curr) => prev + curr?.qty * curr.price,
                    0
                  )
              )}
            </span>
          </div>
          <InputField
            label="Biaya Tambahan"
            type="number"
            disabled={isEdit || isDetail}
            register={forms.register("other_cost")}
            state={
              getErrorField(forms.formState.errors, "other_cose").show
                ? "error"
                : ""
            }
            errors={getErrorField(forms.formState.errors, "other_cost")}
          />
          <div className="flex flex-col">
            <span
              className={`text-sm font-bold text-textDefault dark:text-white`}
            >
              Total
            </span>
            <span>
              {currency(
                forms
                  .watch("po_details")
                  .reduce(
                    (prev, curr) => prev + curr?.qty * curr.price,
                    0
                  ) + +forms.watch("other_cost", 0)
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
