import httpService from "services/api.service";

const BASE_URL = "/transaction";

export const getTransaction = async (payload) => {
  try {
    const res = await httpService.get(BASE_URL, { params: payload });
    return res;
  } catch (error) {
    throw error.message;
  }
};
