import { getButtonVariant, getButtonSize } from "./utils";

const Button = ({ variant, customStyle = '', label, rounded = false, outline = false, size, leftIcon, rightIcon, onClickHandler = () => {}, ...rest }) => {
    const roundedSize = size === 'sm' || size === 'xs' ? 'rounded-lg' : 'rounded-xl'
    const cssClass = `${getButtonVariant(variant, outline)} ${getButtonSize(size)} ${customStyle} ${rounded ? 'rounded-full' : roundedSize} flex flex-row items-center gap-1`;

    return (
        <button className={`${cssClass} disabled:cursor-not-allowed disabled:bg-[#DCDEE4]`} onClick={onClickHandler} {...rest}>
            {leftIcon && (
                leftIcon
            )}
            {label}
            {rightIcon && (
                rightIcon
            )}
        </button>
    );
};
  
export default Button;