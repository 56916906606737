import { STATUS_ENUM } from '../config/enum';

const StatusColumn = ({ status }) => {
    let value = { label: '', style: '' };

    switch (status) {
        case STATUS_ENUM.NON_ACTIVE:
            value = { label: 'Tidak Aktif', style: 'bg-[#E5E5E5] text-[#0A1A1B]' };
            break;

        case STATUS_ENUM.EXPIRED:
            value = { label: 'Kedaluwarsa', style: 'bg-[#FECACA] text-[#E31A1A]' };
            break;

        case STATUS_ENUM.ACTIVE:
            value = { label: 'Aktif', style: 'bg-[#EBFAF5] text-[#04C99E]' };
            break;

        default:
            break;
    }

    return (
        <div
            className={`rounded-full py-1 px-2 tracking-wide ${value.style} whitespace-nowrap w-fit h-fit text-xs`}
        >
            {value.label}
        </div>
    );
};

export default StatusColumn;
