import React from 'react';
import L, { MarkerCluster } from "leaflet";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import 'leaflet/dist/leaflet.css';


// Fixing default marker icons
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const MapWithClusters = ({ height = '25vh', locationList = [] }) => {
  return locationList.length > 0 ? (
    <MapContainer 
      center={[locationList[0]?.lat || 0, locationList[0]?.long || 0]} 
      zoom={10} 
      style={{ height: height, width: '100%' }}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MarkerClusterGroup chunkedLoading>
        {locationList.map((address, index) => (
          <Marker
            key={index}
            position={[address.lat, address.long]}
            title={'location'}
          ></Marker>
        ))}
      </MarkerClusterGroup>
    </MapContainer>
  ) : null;
}

export default MapWithClusters;
