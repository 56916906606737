import React, { useContext } from "react";
import Card from "components/card";
import {
    lineChartOptionsTotalSpent,
} from "variables/charts";
import LineChart from "components/charts/LineChart";
import Text from "components/Text";
import { ContextTransaction } from "../context/ContextProvider";

const TotalSpent = () => {
    const { chartData } = useContext(ContextTransaction);

    return (
        <Card extra="!p-[20px] text-center">
            <div className="relative flex items-center justify-between">
                <Text variant="h1" content="Grafik Penjualan" />
            </div>

            <div className="flex h-full w-full flex-row justify-between">
                <div className="h-[426px] w-full">
                    <LineChart
                        options={lineChartOptionsTotalSpent(chartData.LINE.OPTIONS)}
                        series={chartData.LINE.VALUE}
                    />
                </div>
            </div>
        </Card>
    );
};

export default TotalSpent;
