import Card from "components/card";
import { validations } from "./enum";
import Button from "components/Button";
import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "context/LayoutContext";
import InputSelect from "components/Form/InputSelect";
import { createUser } from "apis/customers";
import { useToast } from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import { getLocations } from "apis/locations";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getErrorField } from "helper/geterrorfield";

const CreateUser = () => {
    const { setHeaderComponent } = useContext(LayoutContext);
    const [locationList, setLocationList] = useState([]);

    const defaultFieldValues = {
        username: '',
        password: "",
        id_region: "",
    }

    const forms = useForm({
        defaultValues: defaultFieldValues,
        resolver: yupResolver(validations),
    });

    const toast = useToast();

    const fetchLocations = async () => {
        try {
          const res = await getLocations({ limit: 20, page: 1 })
  
          const formattedData = res.map(item => ({ value: item.id_region.toString(), label: item.region_name }))
          setLocationList([{ value: '', label: 'Pilih Area' }, ...formattedData])
        } catch (error) {
          toast({
            title: 'Gagal mendapatkan data lokasi',
            status: "error",
            isClosable: true,
            position: "top-center",
          });
        }
    }

    const handleSubmit = forms.handleSubmit(async (values) => {
        try {
          const payload = {
            username: values.email,
            password: values.password,
            id_region: parseInt(values.id_region),
          }
  
          await createUser(payload);

          forms.reset(defaultFieldValues);
          toast({
            title: 'User berhasil dibuat',
            status: "success",
            isClosable: true,
            position: "top-center",
          });
        } catch (error) {
          toast({
            title: error,
            status: "error",
            isClosable: true,
            position: "top-center",
          });
        }
    });

    useEffect(() => {
        fetchLocations()
        setHeaderComponent();
    }, []);

    return (
        <Card extra={"w-full h-full p-6 sm:overflow-x-auto flex-grow mt-5 flex flex-col justify-center items-center"}>
                <div className="flex gap-4 w-full justify-center">
                    <div className="w-1/2">
                        <InputField
                            label="Email"
                            register={forms.register("email")}
                            state={
                                getErrorField(forms.formState.errors, "email").show ? "error" : ""
                            }
                            errors={getErrorField(forms.formState.errors, "email")}
                        />
                    </div>
                </div>
                <div className="flex gap-4 w-full justify-center">
                    <div className="w-1/2">
                        <InputField
                            label="Password"
                            register={forms.register("password")}
                            state={
                                getErrorField(forms.formState.errors, "password").show ? "error" : ""
                            }
                            errors={getErrorField(forms.formState.errors, "password")}
                            type="password"
                        />
                    </div>
                </div>
                <div className="flex gap-4 w-full justify-center">
                    <div className="w-1/2">
                        <InputSelect
                            optionList={locationList}
                            label="Area/Kota"
                            register={forms.register("id_region")}
                            onChange={(e) =>
                                forms.setValue("id_region", e.target.value.toString())
                            }
                            state={
                                getErrorField(forms.formState.errors, "id_region").show ? "error" : ""
                            }
                            errors={getErrorField(forms.formState.errors, "id_region")}
                        />
                    </div>
                </div>
                <div className="flex gap-4 w-full justify-center mt-4">
                    <div className="w-1/2">
                        <Button label="Simpan" disabled={false} variant="DEFAULT" onClickHandler={handleSubmit} size="sm" customStyle="justify-center w-full" />
                    </div>
                </div>
                
        </Card>
    );
};

export default CreateUser;
