import httpService from "services/api.service";

export const getSaleReportExport = async (params) => {
  try {
    const res = await httpService.get("/sale_report_export", { params });

    window.open(res.url, "_blank");
  } catch (error) {
    throw error.response.data.msg;
  }
};

export const getLabaRugiExport = async (params) => {
  try {
    const res = await httpService.get("/laba_rugi_export", { params });

    window.open(res.url, "_blank");
  } catch (error) {
    throw error.response.data.msg;
  }
};

export const getInventoryExport = async (params) => {
  try {
    const res = await httpService.get("/inventory_export", { params });

    window.open(res.url, "_blank");
  } catch (error) {
    throw error.response.data.msg;
  }
};

export const getUserSalesExport = async (params) => {
  try {
    const res = await httpService.get("/user_sales_export", { params });

    window.open(res.url, "_blank");
  } catch (error) {
    throw error.response.data.msg;
  }
};

export const getCustomerExport = async (params) => {
  try {
    const res = await httpService.get("/customer_export", { params });

    window.open(res.url, "_blank");
  } catch (error) {
    throw error.response.data.msg;
  }
};
