import DefaultColumn from "components/Table/components/DefaultColumn";
import { currency } from "helper";
import { MdRemoveRedEye } from "react-icons/md";
import StatusColumn from "../component/StatusColumn";
import moment from "moment";

const TRANSACTION_TYPE = {
    IN: 1,
    OUT: 0,
};

const SALES_LIST = [
    {
        label: 'Semua Sales',
        value: 0
    },
    {
        label: 'Sales 1',
        value: 1
    },
    {
        label: 'Sales 2',
        value: 2
    }
];

const AREA_LIST = [
    {
        label: 'Semua Wilayah',
        value: 0
    },
    {
        label: 'Malang',
        value: 1
    },
    {
        label: 'Surabaya',
        value: 2
    },
    {
        label: 'Pasuruan',
        value: 2
    }
];

const TABLE_COLUMN = (setOpen) => {
    return [
        {
            accessorKey: 'created_at',
            header: 'Waktu Transaksi',
            cell: info => <DefaultColumn>{moment(info.getValue()).format('DD/MM/YYYY')}</DefaultColumn>,
        },
        {
            accessorKey: 'user_sales.sales_name',
            header: 'Nama Sales',
            cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
        },
        {
            accessorKey: 'order_no',
            header: 'Kode Transaksi',
            cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
        },
        {
            accessorKey: 'is_paid',
            header: 'Is Paid',
            cell: info => <StatusColumn status={info.getValue()} />
        },
        {
            accessorKey: 'total',
            header: 'Nominal',
            cell: info => <DefaultColumn>{currency(info.getValue())}</DefaultColumn>,
        },
        {
            accessorKey: 'action',
            header: 'Aksi',
            size: 70,
            cell: info => (
                <div className="flex gap-1">
                    <button className="bg-[#BEDBFE] text-[#3A65FF] rounded-lg w-8 h-8 flex justify-center items-center" onClick={() => { setOpen(info.row.original) }}>
                        <MdRemoveRedEye />
                    </button>
                </div>
            ),
        },
    ]
}

const DUMMY_DATA = [
    {
        id: 1,
        date: '12 Jan 2024 | 13:13',
        note: 'Pembayaran Transaksi',
        code: 'TR#1234',
        type: 1,
        nominal: 1000000,
    },
    {
        id: 1,
        date: '12 Jan 2024 | 13:13',
        note: 'Pembelian Stok Barang',
        code: 'TR#12345',
        type: 2,
        nominal: 1000000,
    }
];

export {
    TRANSACTION_TYPE,
    SALES_LIST,
    AREA_LIST,
    TABLE_COLUMN,
    DUMMY_DATA,
}