import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
// import Footer from "components/footer/Footer";
import routes from "routes.js";
import BreadCrumb from "components/navbar/BreadCrumb";
import LayoutProvider from "context/LayoutContext";

export default function Admin(props) {
    const { ...rest } = props;
    const location = useLocation();
    const [open, setOpen] = React.useState(true);
    const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");
    const [currentRouteChild, setCurrentRouteChild] = React.useState("");

    React.useEffect(() => {
        window.addEventListener("resize", () =>
        window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
        );
    }, []);

    React.useEffect(() => {
        getActiveRoute(routes);
    }, [location.pathname]);

    const getActiveRoute = (routes) => {
        let activeRoute = "Main Dashboard";
        for (let i = 0; i < routes.length; i++) {
            
            if (
                window.location.href.indexOf(
                routes[i].layout + "/" + routes[i].path
                ) !== -1
            ) {
                let currentChild = '';
                if (routes[i].child && routes[i].child.length > 0) {
                    routes[i].child.map(child => {
                        if (window.location.href.indexOf(
                            child.layout + "/" + child.path
                            ) !== -1
                        ) {
                            currentChild = child.name;
                        }
                    });
                }

                setCurrentRouteChild(currentChild);
                setCurrentRoute(routes[i].name);
            }
        }
        return activeRoute;
    };

    const getActiveNavbar = (routes) => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (
                window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
            ) {
                return routes[i].secondary;
            }
        }
        return activeNavbar;
    };

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/admin") {
                return (
                <React.Fragment key={key}>
                    {prop.child && prop.child.map((child, childIndex) => (
                    <Route path={`/${child.path}`} element={child.component} key={`childMenu-${childIndex}`} />
                    ))}
                    <Route path={`/${prop.path}`} element={prop.component} />
                </React.Fragment>
                );
            } else {
                return null;
            }
        });
    };

    document.documentElement.dir = "ltr";

    return (
        <LayoutProvider parentProps={{}} >
            <div className="flex h-full w-full">
                <Sidebar open={open} onClose={() => setOpen(false)} />
                {/* Navbar & Main Content */}
                <div className="h-full w-full bg-lightPrimary">
                    {/* Main Content */}
                    <main
                        className={`h-full flex-none transition-all xl:ml-[272px]`}
                    >
                        {/* Routes */}
                        <div className="h-full">
                            <Navbar
                                onOpenSidenav={() => setOpen(true)}
                                logoText={"Horizon UI Tailwind React"}
                                brandText={currentRoute}
                                secondary={getActiveNavbar(routes)}
                                {...rest}
                            />
                            <BreadCrumb
                                onOpenSidenav={() => setOpen(true)}
                                logoText={"Horizon UI Tailwind React"}
                                brandText={currentRoute}
                                secondary={getActiveNavbar(routes)}
                                childText={currentRouteChild}
                                {...rest}
                            />
                            <div className="pt-5s mx-6 mb-auto flex min-h-[80vh] flex-col p-2">
                                <Routes>
                                    {getRoutes(routes)}

                                    <Route
                                        path="/"
                                        element={<Navigate to="/admin/dashboard" replace />}
                                    />
                                </Routes>
                            </div>
                            {/* <div className="p-3">
                                <Footer />
                            </div> */}
                        </div>
                    </main>
                </div>
            </div>
        </LayoutProvider>
    );
}
