import { removeUser, setUser } from "helper/localstorage";
import httpService from "services/api.service";

export const requestLogin = async (payload) => {
    try {
        const res = await httpService.post('/admin/login', payload);
        setUser(res);

        return res
    } catch (error) {
        throw error.response.data.msg;
    }
};

export const requestLogout = async () => {
    try {
        await httpService.post('/signout');
        
        removeUser()
    } catch (error) {
        throw error.response.data.msg;
    }
};