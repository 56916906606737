import React, { useContext } from 'react';
import Table from 'components/Table';
import { TABLE_COLUMN } from '../config/enum';
import { ContextHistoryStock } from '../context/ContextProvider';

const List = () => {
    const {
        setOpen, salesStockList, table, fetchSalesStockList, setDetailData,
    } = useContext(ContextHistoryStock);
    
    const handleOpenDetail = (original) => {
        setOpen(true);
        setDetailData(original);
    }

    return (
        <div className="mt-8 flex flex-grow overflow-x-scroll xl:overflow-hidden">
            <Table
                columnsData={TABLE_COLUMN({ handleOpenDetail })}
                tableData={salesStockList}
                paginationCallback={(page, offset) => fetchSalesStockList({ page, limit: offset })}
                currentPage={table.pagination.current_page}
                perPage={table.pagination.per_page}
                pageCount={table.pagination.total}
            />
        </div>
    );
};

export default List;
