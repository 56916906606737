import InputSearch from "components/Form/InputSearch";
import InputSelect from "components/Form/InputSelect";
import { debounce } from "helper/index";
import React, { useContext, useEffect, useState } from "react";
import { TYPE_LIST } from "../enum";
import InputDateRange from "components/Form/InputDateRange";
import InputMultipleSelect from "components/Form/InputMultipleSelect";
import { getSales } from "apis/sales";
import { getRegion } from "apis/region";
import { ContextSaleReport } from "../context/ContextProvider";
import { getCustomers } from "apis/customers";
import moment from "moment";
import { getGudang } from "apis/gudang";

const Filter = () => {
  const { query, setQuery, setNames } = useContext(ContextSaleReport);
  const [dateRange, setDateRange] = useState([
    moment().startOf("month").toDate(),
    moment().endOf("month").toDate(),
  ]);

  const [startDate, endDate] = dateRange;

  useEffect(() => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      watch: {
        ...prevQuery.watch,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        page: 1,
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <>
      <div className="flex gap-5">
        <div className="w-1/3">
          <InputSearch
            rounded
            changeHandler={debounce(
              (e) =>
                setQuery((prevQuery) => ({
                  ...prevQuery,
                  watch: {
                    ...prevQuery.watch,
                    keyword: e.target.value,
                    page: 1,
                  },
                })),
              500
            )}
          />
        </div>
        <div className="w-1/3">
          <InputSelect
            optionList={TYPE_LIST}
            onChange={(e) =>
              setQuery((prevQuery) => ({
                ...prevQuery,
                watch: {
                  ...prevQuery.watch,
                  type: e.target.value,
                },
              }))
            }
            rounded
          />
        </div>
        <div className="w-1/3">
          <InputDateRange
            onChange={(update) => setDateRange(update)}
            value={[startDate, endDate]}
            rounded
            className="h-[48px] w-full"
          />
        </div>
      </div>

      <div className="mt-5 flex gap-5">
        <div className="w-1/3">
          <InputMultipleSelect
            fetchList={getCustomers}
            keyLabel="customer_name"
            keyValue="id_customer"
            onChange={(value, title) => {
              setNames((prev) => ({ ...prev, customer: title }));

              setQuery((prevQuery) => ({
                ...prevQuery,
                watch: { ...prevQuery.watch, id_customer: value },
              }));
            }}
            value={query.watch.id_customer}
            rounded
          />
        </div>
        <div className="w-1/3">
          <InputMultipleSelect
            fetchList={getSales}
            keyLabel="sales_name"
            keyValue="id_sales"
            onChange={(value, title) => {
              setNames((prev) => ({ ...prev, sales: title }));

              setQuery((prevQuery) => ({
                ...prevQuery,
                watch: { ...prevQuery.watch, id_sales: value },
              }));
            }}
            value={query.watch.id_sales}
            rounded
          />
        </div>
        <div className="w-1/3">
          <InputMultipleSelect
            fetchList={getGudang}
            noLimit
            keyLabel="gudang_name"
            keyValue="id_gudang"
            onChange={(value, title) => {
              setNames((prev) => ({ ...prev, gudang: title }));

              setQuery((prevQuery) => ({
                ...prevQuery,
                watch: { ...prevQuery.watch, id_gudang: value },
              }));
            }}
            value={query.watch.id_gudang}
            rounded
          />
        </div>
      </div>
    </>
  );
};

export default Filter;
