import { useContext } from "react";
import { ContextPurchaseOrder } from "../context/ContextProvider";
import Text from "components/Text";
import { defaultFieldValues, FORM_MODE } from "../config/enum";
import ModalContainer from "components/Modal";
import { useFormContext } from "react-hook-form";
import { createPO, updatePO } from "apis/purchase_order";
import moment from "moment";
import Form from "./Form";

const ModalCreate = () => {
  const { openCreate, setOpenCreate, fetchPO, toast, isDetail } =
    useContext(ContextPurchaseOrder);
  const forms = useFormContext();

  const handleSubmit = forms.handleSubmit(async (values) => {
    try {
      const formattedDetails = values.po_details.map((item) => ({
        ...(values.formType === FORM_MODE.EDIT && {
          id_purchase_order_detail: item.id_purchase_order_detail,
          id_purchase_order: item.id_purchase_order,
          id_product: item.product.id_product,
          id_unit: item.id_unit,
        }),
        id_product: item.id_product,
        qty: item.qty,
        price: item.price,
        note: "",
      }));

      const total =
        formattedDetails.reduce(
          (acc, item) => acc + parseInt(item.qty) * parseInt(item.price),
          0
        ) + (values.other_cost ? +values.other_cost || 0 : 0);
      const payload = {
        ...(values.formType === FORM_MODE.EDIT && {
          id_customer: values.id_customer,
          id_purchase_order: values.id_purchase_order,
          no_po: values.no_po,
        }),
        id_user: values.id_user,
        supplier_name: values.supplier_name,
        po_date: moment(values.po_date).format("YYYY-MM-DD"),
        status: values.status,
        total,
        other_cost: values.other_cost,
        id_gudang: values.id_gudang,
        po_details: formattedDetails,
      };

      if (values.formType === FORM_MODE.CREATE) {
        await createPO(payload);
      } else {
        await updatePO(payload);
      }

      setOpenCreate(false);

      forms.reset(defaultFieldValues);

      fetchPO({ page: 1, limit: 10 });
    } catch (error) {
      toast({
        title: error,
        status: "error",
        isClosable: true,
        position: "top-center",
      });
    }
  });

  const handleDraft = () => {
    forms.setValue("status", 1);
    handleSubmit();
  };

  return (
    <ModalContainer
      isOpen={openCreate}
      modalTitle={`${
        forms.watch("formType") === "create"
          ? "Tambah"
          : forms.watch("formType") === "detail"
          ? "Detail"
          : "Edit"
      } Purchase Order`}
      onClose={() => {
        setOpenCreate(false);
        forms.reset(defaultFieldValues);
      }}
      onSubmit={handleSubmit}
      isSubmitting={forms.formState.isSubmitting}
      hideButton={isDetail}
      onDraft={handleDraft}
    >
      <Text variant="h3" content="Informasi Dasar" />
      <Form />
    </ModalContainer>
  );
};

export default ModalCreate;
