import BGRT from '../../assets/img/404/bgtr.png';
import BGBL from '../../assets/img/404/bgbl.png';
import PageLost from '../../assets/img/404/page-lost.png';
import Text from 'components/Text';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <div>
            <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
                <main className="mx-auto min-h-screen">
                    <div className="relative flex">
                        <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0">
                            <img
                                src={BGRT}
                                alt=""
                                className="absolute right-[0px] w-[400px]"
                            />
                            <img
                                src={BGBL}
                                alt=""
                                className="absolute bottom-[0px] w-[400px]"
                            />

                            <div className="flex flex-col items-center justify-center">
                                <img
                                    src={PageLost}
                                    alt=""
                                    className="w-[600px]"
                                />

                                <h4 className="mb-2.5 text-4xl font-bold text-textDefault dark:text-white">
                                    Halaman Tidak Tersedia
                                </h4>
                                <p className="mb-7 ml-1 text-base text-textDefault/50">
                                    Mohon maaf, kami tidak dapat menemukan
                                    halaman yang kamu tuju
                                </p>
                                <div className="cursor-pointer" onClick={() => navigate('/admin')}>
                                    <Text
                                        variant="linkBold"
                                        content="Kembali ke Halaman Utama →"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default NotFound;
