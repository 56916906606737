const currency = (param) => {
  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  // Format the amount into IDR currency
  return formatter.format(param);
};

const numberSeparator = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

const removeEmptyValues = (object) =>
  Object.fromEntries(Object.entries(object).filter(([_, value]) => value));

export { currency, numberSeparator, debounce, removeEmptyValues };
