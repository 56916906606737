import Card from "components/card";
import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutContext } from "context/LayoutContext";
import Button from "components/Button";
import { MdOutlineFileUpload } from "react-icons/md";
import InputSelect from "components/Form/InputSelect";
import Text from "components/Text";
import { currency } from "helper";
import moment from "moment";
import { getLabaRugiReport } from "apis/laba_rugi";
import { useToast } from "@chakra-ui/react";
import { getLabaRugiExport } from "apis/export";

const MONTH_LIST = Array.from({ length: 12 }, (_, i) => ({
  label: moment().month(i).format("MMMM YYYY"),
  value: i + 1,
}));

const DUMMY_DATA = [
  {
    label: "Pendapatan",
    value: "",
    type: "heading",
  },
  {
    label: "Penjualan Usaha",
    value: currency(0),
    type: "content",
  },
  {
    label: "Pendapatan Lainnya",
    value: currency(0),
    type: "content",
  },
  {
    label: "Beban",
    value: "",
    type: "heading",
  },
  {
    label: "Harga Pokok Penjualan",
    value: currency(0),
    type: "content",
  },
  {
    label: "Beban Penjualan",
    value: currency(0),
    type: "content",
  },
  {
    label: "Total Pendapatan",
    value: currency(0),
    type: "heading",
  },
  {
    label: "Total Beban",
    value: `(${currency(0)})`,
    type: "heading",
  },
  {
    label: "Laba Sebelum Pajak",
    value: currency(0),
    type: "heading",
  },
  {
    label: "Laba Bersih",
    value: currency(0),
    type: "heading",
  },
];

const LabaRugi = () => {
  const toast = useToast();
  const { setHeaderComponent } = useContext(LayoutContext);
  const [selectedMonth, setSelectedMonth] = useState(MONTH_LIST[0].value);
  const [dataList, setDataList] = useState(DUMMY_DATA);

  const fetchData = async () => {
    try {
      const payload = { month: selectedMonth };
      const response = await getLabaRugiReport(payload);

      const formattedData = [
        {
          label: "Pendapatan",
          value: "",
          type: "heading",
        },
        {
          label: "Penjualan Usaha",
          value: currency(response?.total_sales),
          type: "content",
        },
        {
          label: "Pendapatan Lainnya",
          value: currency(response?.other_income),
          type: "content",
        },
        {
          label: "Beban",
          value: "",
          type: "heading",
        },
        {
          label: "Harga Pokok Penjualan",
          value: currency(response?.hpp),
          type: "content",
        },
        {
          label: "Beban Penjualan",
          value: currency(response?.other_expanses),
          type: "content",
        },
        {
          label: "Total Pendapatan",
          value: currency(response?.total_income),
          type: "heading",
        },
        {
          label: "Total Beban",
          value: `(${currency(response?.total_expanses)})`,
          type: "heading",
        },
        {
          label: "Laba Sebelum Pajak",
          value: currency(response?.total_revenue),
          type: "heading",
        },
        {
          label: "Laba Bersih",
          value: currency(response?.total_revenue),
          type: "heading",
        },
      ];

      setDataList(formattedData);
    } catch (err) {
      setDataList(DUMMY_DATA);
      toast({
        title: "Gagal",
        description: err,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const exportWithQuery = useCallback(() => {
    return getLabaRugiExport({ month: selectedMonth });
  }, [selectedMonth]);

  useEffect(() => {
    setHeaderComponent(
      <div className="flex h-full items-end justify-end gap-3">
        <Button
          onClickHandler={() => exportWithQuery()}
          label="Export Excel"
          variant="GHOST"
          size="sm"
          leftIcon={<MdOutlineFileUpload />}
        />
      </div>
    );
  }, [exportWithQuery]);

  useEffect(() => {
    fetchData();
  }, [selectedMonth]);

  return (
    <div>
      <div className="mt-5">
        <Card extra={"w-full h-full p-6 sm:overflow-x-auto"}>
          <div className="flex gap-5">
            <div className="w-1/3">
              <InputSelect
                optionList={MONTH_LIST}
                rounded
                onChange={(e) => {
                  setSelectedMonth(e.target.value);
                }}
                value={selectedMonth}
              />
            </div>
          </div>

          <div className="mt-5 flex flex-col">
            {dataList.map((data) => (
              <div
                className={`flex justify-between ${
                  data.type === "heading" ? "bg-backgroundLight" : ""
                } p-4`}
              >
                <Text
                  content={data.label}
                  variant={`${data.type === "heading" ? "linkBold" : "link"}`}
                />
                <Text
                  content={data.value}
                  variant={`${data.type === "heading" ? "linkBold" : "link"}`}
                />
              </div>
            ))}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default LabaRugi;
