import httpService from "services/api.service";

export const createProduct = async (payload) => {
  try {
    const res = await httpService.post("/product", payload);

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};

export const updateProduct = async (payload) => {
  try {
    const res = await httpService.put(`/update_product/${payload.id_product}`, payload);

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};

export const getProduct = async (params) => {
  try {
    const res = await httpService.get("/product", { params });

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};

export const deleteProduct = async (payload) => {
  try {
    const res = await httpService.put(`/delete_product/${payload.id}`);

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};