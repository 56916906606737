import { useState } from "react"

export const useHistoryStockList = () => {
    const [open, setOpen] = useState(false);
    const [openSN, setOpenSN] = useState(false);

    return {
        open,
        setOpen,
        openSN,
        setOpenSN,
    }
}