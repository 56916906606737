import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import Card from "components/card";
import Text from "components/Text";
import Button from "components/Button";
     
const Confirmation = ({ isOpen = false, modalTitle = '', isSubmitting, children, onClose = () => {}, onSubmit = () => {} }) => {
    const containerClass = `px-[30px] pt-[35px] pb-[40px] flex flex-col`;
    return (
        <Modal isOpen={isOpen} onClose={onClose} className="z-[2]">
            <ModalOverlay className="bg-[#000] !opacity-30" zIndex='2000' />
            <ModalContent
                className={`!m-auto !w-fit md:top-[12vh]`}
                containerProps={{
                    zIndex: '2000',
                }}
            >
                <ModalBody>
                    <Card extra={containerClass}>
                        <Text variant="h1" content={modalTitle} />
                        <div className="border-b-[1px] border-[#E4E5E8] my-3"></div>
                        <div className="mt-6 mb-6">
                            {children}
                        </div>
                        <div className="flex gap-2 mt-2 w-full justify-end">
                            <Button label="Batal" disabled={isSubmitting} variant="SECONDARY" size="sm" outline onClickHandler={onClose} customStyle="justify-center" />
                            <Button label="Ya, Lanjutkan" disabled={isSubmitting} variant="RED" onClickHandler={onSubmit} customStyle="justify-center" />
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    ); 
};

export default Confirmation;