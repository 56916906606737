import Table from "components/Table";
import Card from "components/card";
import { TableColumn } from "./enum";
import Button from "components/Button";
import { MdAdd, MdOutlineFileUpload } from "react-icons/md";
import InputSelect from "components/Form/InputSelect";
import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "context/LayoutContext";
import InputSearch from "components/Form/InputSearch";
import Modal from "components/Modal";
import { useToast } from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import { useForm } from "react-hook-form";
import {
  createProductCategory,
  deleteProductCategory,
  updateProductCategory,
} from "apis/product_category";
import { debounce } from "helper/index";
import { yupResolver } from "@hookform/resolvers/yup";
import { validations } from "./utils";
import { getErrorField } from "helper/geterrorfield";
import {
  createGudang,
  deleteGudang,
  getGudang,
  updateGudang,
} from "apis/gudang";
import { getLocations } from "apis/locations";
import TextField from "components/fields/TextField";

import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { getSales } from "apis/sales";
import InputMultipleSelect from "components/Form/InputMultipleSelect";
import { getUser } from "helper/localstorage";
import Text from "components/Text";
import { getUsers } from "apis/users";
import { getCity } from "apis/city";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const Kategori = () => {
  const { setHeaderComponent } = useContext(LayoutContext);
  const initialFormValue = {
    id_region: "",
    id_user_pic: null,
    assigned_user_sales: [],
    gudang_name: "",
    lat: -7.983908,
    long: 112.621391,
    address: "",
  };
  const forms = useForm({
    defaultValues: initialFormValue,
    resolver: yupResolver(validations),
  });
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const [locationList, setLocationList] = useState([]);
  const [phoneSales, setPhoneSales] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [table, setTable] = useState({
    loading: false,
    data: [],
    pagination: {
      total_data: 1,
      total_page: 1,
      current_page: 1,
      per_page: 10,
      status: "",
      keyword: "",
    },
  });

  const fetchLocations = async () => {
    try {
      const res = await getLocations({ limit: 10, page: 1 });

      const formattedData = res.map((item) => ({
        value: item.id_region,
        label: item.region_name,
      }));
      setLocationList([{ value: "", label: "Pilih Area" }, ...formattedData]);
    } catch (error) {
      toast({
        title: "Gagal mendapatkan data lokasi",
        status: "error",
        isClosable: true,
        position: "top-center",
      });
    }
  };

  const fetchTable = async (params = {}) => {
    const response = await getGudang(params);

    setTable((prev) => ({
      ...prev,
      data: response.data,
      pagination: {
        total_page: response.last_page,
        total_data: response.total,
        per_page: response.per_page,
        current_page: response.current_page,
        status: params.status,
        keyword: params.keyword,
      },
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchTable();
      } catch (err) {
        toast({
          title: "Gagal",
          description: err,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpenForm) {
      fetchLocations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenForm]);

  const SELECT_DATA = [
    {
      label: "Semua Status",
      value: "",
    },
    {
      label: "Tampil",
      value: 1,
    },
    {
      label: "Tidak Tampil",
      value: 0,
    },
  ];

  const toast = useToast();

  const handleSubmit = forms.handleSubmit(async (values) => {
    delete values?.sales_labels;
    try {
      if (values.id) {
        await updateGudang(values);
      } else {
        await createGudang(values);
      }

      setIsOpenForm(false);

      forms.reset(initialFormValue);

      fetchTable({ page: table.pagination.current_page, limit: 10 });

      toast({
        title: "Berhasil",
        description: `Sukses ${values.id ? "mengedit" : "menambahkan"} gudang`,
        status: "success",
        isClosable: true,
        position: "top-right",
      });
    } catch (err) {
      toast({
        title: "Gagal",
        description: err,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  });

  const onTableChange = (tableState) => {
    console.log(tableState);
  };

  useEffect(() => {
    setHeaderComponent(
      <div className="flex h-full items-end justify-end gap-3">
        <Button
          label="Export Excel"
          variant="GHOST"
          size="sm"
          leftIcon={<MdOutlineFileUpload />}
        />
        <Button
          label="Tambah Gudang"
          variant="DEFAULT"
          size="sm"
          leftIcon={<MdAdd />}
          onClickHandler={() => {
            setIsOpenForm(!isOpenForm);
          }}
        />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsOpenForm, isOpenForm]);

  const LocationMarker = ({ position, setPosition, isDetail }) => {
    const map = useMapEvents({
      click(e) {
        if (isDetail) return;

        const { lat, lng } = e.latlng;
        setPosition([lat, lng]);
        map.flyTo(e.latlng, map.getZoom());
      },
    });

    return position ? (
      <Marker position={position}>
        <Popup>
          <div className="text-sm">
            <p className="mb-1 font-semibold">Selected Location:</p>
            <p>Latitude: {position[0].toFixed(6)}</p>
            <p>Longitude: {position[1].toFixed(6)}</p>
          </div>
        </Popup>
      </Marker>
    ) : null;
  };

  const getUserRole = () => getUsers({ id_role: 2 });
  const getSalesBasic = () => getSales({ id_sales_role: 1 });
  const getCityByProvince = () =>
    getCity({ id_region: forms.watch("id_region") });

  console.log(dataDetail)
  return (
    <Card
      extra={
        "w-full h-full p-6 sm:overflow-x-auto flex-grow mt-5 flex flex-col"
      }
    >
      <div className="relative flex items-center gap-3">
        <div className="flex-grow">
          <InputSearch
            rounded
            changeHandler={debounce(
              (e) =>
                fetchTable({
                  keyword: e.target.value,
                  page: 1,
                  limit: 10,
                  status: table.pagination.status,
                }),
              500
            )}
          />
        </div>
        <div className="w-1/5" />
      </div>

      <div className="mt-8 flex flex-grow overflow-x-scroll xl:overflow-hidden">
        <Table
          columnsData={TableColumn(
            (data) => {
              forms.reset({
                id: data.id_gudang,
                gudang_name: data.gudang_name,
                id_user_pic: data.id_user_pic,
                id_region: data.id_region,
                id_city: data.id_city,
                assigned_user_sales: data.user_gudangs.map((t) => t.id_sales),
                lat: data.lat,
                long: data.long,
                address: data.address,
              });
              setIsOpenForm(true);
            },
            (data) => {
              forms.setValue("id", data.id_gudang);
              setDeleteModal(true);
            },
            (data) => {
              setDataDetail(data);
              setIsOpenDetail(true);
            }
          )}
          tableData={table.data}
          paginationCallback={(page, offset) =>
            fetchTable({ page, limit: offset })
          }
          totalData={table.pagination.total_data}
          currentPage={table.pagination.current_page}
          perPage={table.pagination.per_page}
          pageCount={table.pagination.total_page}
          onTableChange={onTableChange}
        />
      </div>
      <Modal
        onClose={() => {
          setDataDetail({});
          setIsOpenDetail(false);
        }}
        isOpen={isOpenDetail}
        hideButton
        modalTitle="Detail Gudang"
      >
        <div className="min-w-[800px] rounded-lg border border-gray-200 bg-white shadow-sm">
          <div className="p-6">
            <h2 className="mb-4 text-lg font-semibold">Informasi Gudang</h2>
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <div className="text-gray-600">Nama Gudang</div>
                <div className="text-gray-900">{dataDetail?.gudang_name}</div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-gray-600">Provinsi/Wilayah</div>
                <div className="text-gray-900">
                  {dataDetail?.region?.region_name}
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-gray-600">Kota</div>
                <div className="text-gray-900">
                  {dataDetail?.city?.name ?? '-'}
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-gray-600">PIC</div>
                <div className="text-gray-900">
                  {dataDetail?.user_sales?.sales_name}
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-gray-600">Nomor Kontak PIC</div>
                <div className="text-gray-900">
                  {dataDetail?.user_sales?.phone || "-"}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
          <div className="p-6">
            <h2 className="mb-4 text-lg font-semibold">Lokasi Gudang</h2>
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <div className="w-1/3 text-gray-600">Pin Poin</div>
                <div className="w-2/3 max-w-[400px] break-words text-right text-gray-900">
                  {dataDetail?.address}
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-gray-600">Provinsi/Wilayah</div>
                <div className="text-gray-900">
                  {dataDetail?.region?.region_name}
                </div>
              </div>
            </div>

            <div className="mt-4 flex h-64 w-full items-center justify-center rounded-lg bg-gray-100">
              <MapContainer
                center={[dataDetail.lat, dataDetail.long]}
                zoom={13}
                style={{ height: "350px", width: "100%" }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <LocationMarker
                  position={[dataDetail.lat, dataDetail.long]}
                  isDetail
                />
              </MapContainer>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={deleteModal}
        modalTitle="Hapus Gudang"
        onClose={() => {
          forms.reset(initialFormValue);
          setDeleteModal(false);
        }}
        isSubmitting={forms.formState.isSubmitting}
        onSubmit={async () => {
          await deleteGudang({ id: forms.watch("id") });

          await fetchTable({ page: table.pagination.current_page, limit: 10 });

          toast({
            title: "Berhasil",
            description: `Sukses menghapus gudang`,
            status: "success",
            isClosable: true,
            position: "top-right",
          });

          setDeleteModal(false);
        }}
      >
        Apakah anda yakin ingin menghapus gudang ini?
      </Modal>
      <Modal
        isOpen={isOpenForm}
        modalTitle={`${forms.watch("id") ? "Edit" : "Tambah"} Gudang`}
        onClose={() => {
          forms.reset(initialFormValue);
          setIsOpenForm(!isOpenForm);
        }}
        isSubmitting={forms.formState.isSubmitting}
        onSubmit={handleSubmit}
      >
        <div className="flex w-[600px] flex-col gap-4">
          <div className="flex w-full items-center gap-4">
            <InputField
              extra="w-1/2"
              register={forms.register("gudang_name")}
              label="Nama Gudang"
              required
              state={
                getErrorField(forms.formState.errors, "gudang_name").show
                  ? "error"
                  : ""
              }
              errors={getErrorField(forms.formState.errors, "gudang_name")}
            />
            <InputSelect
              extra="w-1/2"
              optionList={locationList}
              label="Provinsi/Wilayah Gudang"
              value={forms.watch("id_region")}
              onChange={(e) =>
                forms.setValue("id_region", e.target.value, {
                  shouldValidate: true,
                })
              }
              state={
                getErrorField(forms.formState.errors, "id_region").show
                  ? "error"
                  : ""
              }
              errors={getErrorField(forms.formState.errors, "id_region")}
            />
          </div>
          <div className="w-1/2 pr-2">
            <InputMultipleSelect
              required
              label="Kota"
              disabled={!forms.watch("id_region")}
              fetchList={getCityByProvince}
              keyLabel="name"
              keyValue="id_city"
              initialLabel={"Pilih Kota"}
              state={
                getErrorField(forms.formState.errors, "id_city").show
                  ? "error"
                  : ""
              }
              getLabels={(labels) => forms.setValue("sales_labels", labels)}
              errors={getErrorField(forms.formState.errors, "id_city")}
              onChange={(value) => {
                forms.setValue("id_city", value, {
                  shouldValidate: true,
                });
              }}
              value={forms.watch("id_city", [])}
            />
          </div>
          <MapContainer
            center={[forms.watch("lat"), forms.watch("long")]}
            zoom={13}
            style={{ height: "350px", width: "100%" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <LocationMarker
              position={[forms.watch("lat"), forms.watch("long")]}
              setPosition={([lat, long]) => {
                forms.setValue("lat", lat);
                forms.setValue("long", long);
              }}
            />
          </MapContainer>
          <TextField
            register={forms.register("address")}
            label="Alamat"
            required
            state={
              getErrorField(forms.formState.errors, "address").show
                ? "error"
                : ""
            }
            rows={4}
            placeholder="Masukkan nama jalan, kecamatan, kota, dan propinsi gudang"
            errors={getErrorField(forms.formState.errors, "address")}
          />
          <div className="flex w-full items-start gap-4">
            <div className="w-1/2">
              <InputMultipleSelect
                required
                label="PIC"
                fetchList={getUserRole}
                keyLabel="username"
                keyValue="id_user"
                initialLabel={"Pilih PIC"}
                state={
                  getErrorField(forms.formState.errors, "id_user_pic").show
                    ? "error"
                    : ""
                }
                getLabels={(labels) => forms.setValue("sales_labels", labels)}
                errors={getErrorField(forms.formState.errors, "id_user_pic")}
                onChange={(value) => {
                  forms.setValue("id_user_pic", value, {
                    shouldValidate: true,
                  });
                }}
                value={forms.watch("id_user_pic", [])}
              />
            </div>
            <div className="w-1/2">
              <InputMultipleSelect
                required
                label="Sales"
                fetchList={getSalesBasic}
                isMultiple
                keyLabel="sales_name"
                keyValue="id_sales"
                initialLabel={"Pilih Sales"}
                state={
                  getErrorField(forms.formState.errors, "assigned_user_sales")
                    .show
                    ? "error"
                    : ""
                }
                getLabels={(labels) => forms.setValue("sales_labels", labels)}
                errors={getErrorField(
                  forms.formState.errors,
                  "assigned_user_sales"
                )}
                onChange={(value, opt) => {
                  setPhoneSales(opt.map((o) => o.phone));
                  forms.setValue("assigned_user_sales", value, {
                    shouldValidate: true,
                  });
                }}
                value={forms.watch("assigned_user_sales", [])}
              />
              {forms.watch("sales_labels", []).length > 0 && (
                <div className="mt-2 flex items-center gap-2">
                  {forms.watch("sales_labels", []).map((label) => (
                    <span className="rounded-xl bg-[#eeeeee] px-2" key={label}>
                      {label}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </Card>
  );
};

export default Kategori;
