import { uploadImage } from "apis/uploader";
import React, { useRef } from "react";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { useToast } from "@chakra-ui/react";

const InputUpload = (props) => {
  const { icon, image, setValue, ...rest } = props;
  const fileInput = useRef(); /* create a ref*/
  const toast = useToast();

  const handleChange = async (e) => {
    try {
      const response = await uploadImage(e.target.files);

      setValue(response.data);
    } catch (err) {
      toast({
        title: "Gagal",
        description: err,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const defaultIcon = icon || (
    <MdOutlineAddPhotoAlternate className="text-3xl text-[#4318FF]" />
  );

  return (
    <div
      onClick={() => fileInput.current.click()}
      className="aspect-square w-full rounded-xl border-2 border-dashed border-[#E4E5E8] bg-[#F4F7FE]"
    >
      <label
        htmlFor="upload-button"
        className="flex aspect-square w-full cursor-pointer items-center justify-center"
      >
        {image ? (
          <img src={image} alt="dummy" width="300" height="300" />
        ) : (
          defaultIcon
        )}
      </label>
      <input
        type="file"
        {...rest}
        ref={(e) => {
          fileInput.current = e;
        }}
        style={{ display: "none" }}
        onChange={handleChange}
      />
    </div>
  );
};

export default InputUpload;
