import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import Penjualan from "views/admin/report/penjualan";
import Kategori from "views/admin/inventory/kategori";
import Produk from "views/admin/inventory/produk";
import Pelanggan from "views/admin/pelanggan";
import Sales from "views/admin/sales";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
    MdBarChart,
    MdLock,
    MdInsertChart,
    MdDashboard,
    MdShoppingBag,
    MdOutlineStore,
    MdAllInbox,
    MdStickyNote2,
} from "react-icons/md";
import { HiTicket } from "react-icons/hi";
import StockList from "views/admin/inventory/stockList";
import HistoryStock from "views/admin/inventory/historyStock";
import TransferStock from "views/admin/inventory/transferStock";
import Inventori from "views/admin/report/inventori";
import Gudang from "views/admin/gudang";
import LabaRugi from "views/admin/report/labaRugi";
import PurchaseOrder from "views/admin/inventory/purchaseOrder";
import Promo from "views/admin/promo";
import Transaction from "views/admin/transaction";
import SalesStock from "views/admin/inventory/salesStock";
import SalesCash from "views/admin/report/salesCash";
import GeoLocation from "views/admin/report/geoLocation";
import CreateUser from "views/admin/createUser";

const routes = [
    {
        name: "Dashboard",
        layout: "/admin",
        path: "dashboard",
        icon: <MdDashboard className="h-6 w-6" />,
        component: <MainDashboard />,
    },
    {
        name: "Laporan",
        layout: "/admin",
        path: "laporan",
        icon: <MdInsertChart className="h-6 w-6" />,
        component: <MainDashboard />,
        secondary: true,
        child: [
            {
                name: "Laporan Penjualan",
                layout: "/admin",
                icon: <MdBarChart className="h-6 w-6" />,
                path: "laporan/penjualan",
                component: <Penjualan />,
            },
            {
                name: "Laporan Laba Rugi",
                layout: "/admin",
                icon: <MdBarChart className="h-6 w-6" />,
                path: "laporan/laba-rugi",
                component: <LabaRugi />,
            },
            {
                name: "Laporan Inventori",
                layout: "/admin",
                icon: <MdBarChart className="h-6 w-6" />,
                path: "laporan/inventory",
                component: <Inventori />,
            },
            {
                name: "Laporan Kas Sales",
                layout: "/admin",
                icon: <MdBarChart className="h-6 w-6" />,
                path: "laporan/kas-sales",
                component: <SalesCash />,
            },
            {
                name: "Laporan Geo Location",
                layout: "/admin",
                icon: <MdBarChart className="h-6 w-6" />,
                path: "laporan/geo-location",
                component: <GeoLocation />,
            },
        ]
    },
    {
        name: "Produk",
        layout: "/admin",
        path: "produk",
        icon: <MdShoppingBag className="h-6 w-6" />,
        component: <MainDashboard />,
        secondary: true,
        child: [
            {
                name: "Kategori Produk",
                layout: "/admin",
                icon: <MdBarChart className="h-6 w-6" />,
                path: "produk/kategori",
                component: <Kategori />,
            },
            {
                name: "Daftar Produk",
                layout: "/admin",
                icon: <MdBarChart className="h-6 w-6" />,
                path: "produk/list",
                component: <Produk />,
            },
        ]
    },
    {
        name: "Inventori",
        layout: "/admin",
        path: "inventori",
        icon: <MdAllInbox className="h-6 w-6" />,
        component: <MainDashboard />,
        secondary: true,
        child: [
            {
                name: "Daftar Stok",
                layout: "/admin",
                icon: <MdBarChart className="h-6 w-6" />,
                path: "inventori/daftar-stok",
                component: <StockList />,
            },
            {
                name: "Stok Opname",
                layout: "/admin",
                icon: <MdBarChart className="h-6 w-6" />,
                path: "inventori/stok-opname",
                component: <TransferStock />,
            },
            {
                name: "Purchase Order",
                layout: "/admin",
                icon: <MdBarChart className="h-6 w-6" />,
                path: "inventori/purchase-order",
                component: <PurchaseOrder />,
            },
            {
                name: "Riwayat Stok",
                layout: "/admin",
                icon: <MdBarChart className="h-6 w-6" />,
                path: "inventori/riwayat-stok",
                component: <HistoryStock />,
            },
            {
                name: "Stok Sales",
                layout: "/admin",
                icon: <MdBarChart className="h-6 w-6" />,
                path: "inventori/stok-sales",
                component: <SalesStock />,
            },
        ]
    },
    {
        name: "Sales & Pelanggan",
        layout: "/admin",
        path: "sales-and-customer",
        icon: <MdOutlineStore className="h-6 w-6" />,
        component: <MainDashboard />,
        secondary: true,
        child: [
            {
                name: "Data Karyawan",
                layout: "/admin",
                icon: <MdBarChart className="h-6 w-6" />,
                path: "sales-and-customer/data-karyawan",
                component: <Sales />,
            },
            {
                name: "Data Pelanggan",
                layout: "/admin",
                icon: <MdBarChart className="h-6 w-6" />,
                path: "sales-and-customer/data-pelanggan",
                component: <Pelanggan />,
            },
            {
                name: "Buat User",
                layout: "/admin",
                icon: <MdBarChart className="h-6 w-6" />,
                path: "sales-and-customer/buat-user",
                component: <CreateUser />,
            },
        ]
    },
    {
        name: "Daftar Gudang",
        layout: "/admin",
        path: "gudang",
        icon: <MdDashboard className="h-6 w-6" />,
        component: <Gudang />,
    },
    {
        name: "Promo",
        layout: "/admin",
        path: "promo",
        icon: <HiTicket className="h-6 w-6" />,
        component: <MainDashboard />,
        secondary: true,
        child: [
            {
                name: "Daftar Promo",
                layout: "/admin",
                icon: <MdBarChart className="h-6 w-6" />,
                path: "promo/daftar-promo",
                component: <Promo />,
            },
        ]
    },
    {
        name: "Transaksi",
        layout: "/admin",
        path: "transaction",
        icon: <MdStickyNote2 className="h-6 w-6" />,
        component: <MainDashboard />,
        secondary: true,
        child: [
            {
                name: "Daftar Transaksi",
                layout: "/admin",
                icon: <MdStickyNote2 className="h-6 w-6" />,
                path: "transaction/list",
                component: <Transaction />,
            },
        ]
    },
    {
        name: "Sign In",
        layout: "/auth",
        path: "sign-in",
        icon: <MdLock className="h-6 w-6" />,
        component: <SignIn />,
    },
];
export default routes;
