import { getInputSize } from "../utils";
import Text from "components/Text";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./style.css"

const InputDatepicker = ({
    label = "",
    size = "md",
    value = new Date(),
    onChange = () => {},
    rounded,
    className,
    required,
    disabled
}) => {
    const roundedSize = size === "sm" || size === "xs" ? "rounded-lg" : "rounded-xl";

    return (
        <div className="flex flex-col gap-2">
            {label && <div className="flex gap-1"><Text variant="linkBold" content={label} /> {required && <span className="text-[#DF2A36]">*</span>}</div>}
            <ReactDatePicker
                showIcon
                preventOpenOnFocus
                disabled={disabled}
                className={`${rounded ? 'rounded-full' : roundedSize} disabled:cursor-not-allowed disabled:bg-gray-100 border bg-white ${getInputSize(size)} ${className}`}
                selected={value}
                onChange={(date) => onChange(date)}
            />
        </div>
    );
};

export default InputDatepicker;
