import { useContext } from 'react';
import { ContextHistoryStock } from '../context/ContextProvider';
import Text from 'components/Text';
import Table from 'components/Table';
import { TABLE_COLUMN_DETAIL } from '../config/enum';
import StatusColumn from './StatusColumn';
import ModalContainer from 'components/Modal';

const ModalDetail = () => {
    const { open, setOpen, setOpenSN, detailData } = useContext(ContextHistoryStock);

    return (
        <ModalContainer
            isOpen={open}
            modalTitle={`Detail`}
            onClose={() => { setOpen(false) }}
            hideButton
        >
            <Text variant="h3" content="Informasi Dasar" />
            <div className="mt-6 mb-6">
                <div className="flex gap-4">
                    <div className="flex flex-grow flex-col gap-2">
                        <Text variant="linkBold" content="Status" />
                        <StatusColumn status={detailData.status} />
                    </div>
                    <div className="flex flex-grow flex-col gap-2">
                        <Text variant="linkBold" content="Sales" />
                        <Text variant="p" content={detailData.username} />
                    </div>
                    <div className="flex flex-grow flex-col gap-2">
                        <Text
                            variant="linkBold"
                            content="No Transfer Stok"
                        />
                        <Text variant="p" content={detailData.no_po} />
                    </div>
                    <div className="flex flex-grow flex-col gap-2">
                        <Text
                            variant="linkBold"
                            content="Tanggal Transfer Stok"
                        />
                        <Text variant="p" content={detailData.po_date} />
                    </div>
                    <div className="flex flex-grow flex-col gap-2">
                        <Text
                            variant="linkBold"
                            content="Catatan"
                        />
                        <Text variant="p" content="-" />
                    </div>
                </div>
            </div>
            <div className="my-3 border-b-[1px] border-[#E4E5E8]"></div>
            <Table
                columnsData={TABLE_COLUMN_DETAIL({ setOpenSN })}
                tableData={detailData.details}
                hidePagination
            />
        </ModalContainer>
    );
};

export default ModalDetail;
