export const dataRawUser = () => {
    const dataRaw = localStorage.getItem('taraswin:user') || '';
    if (!dataRaw) return null;
    if (dataRaw === 'undefined') return null;
    if (dataRaw === undefined) return null;
    const parsedData = JSON.parse(dataRaw);
    return parsedData;
};

export const getUser = () => dataRawUser() ? ({ name: dataRawUser().user_data.username, email: dataRawUser().email, id: dataRawUser().user_data.id_user, id_role: dataRawUser().user_data.id_role }) : null;

export const getToken = () => dataRawUser() ? dataRawUser().access_token : null;

export const setUser = async (data) => localStorage.setItem('taraswin:user', JSON.stringify(data));

export const removeUser = async () => localStorage.removeItem('taraswin:user');