import httpService from "services/api.service";

const BASE_URL = '/sales_roles';

export const getRoles = async (payload) => {
    try {
        const res = await httpService.get(BASE_URL, payload);
        return res;
    } catch (error) {
        throw error.response.data.msg;
    }
};