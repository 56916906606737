import ActionColumn from "components/Table/components/ActionColumn";
import DefaultColumn from "components/Table/components/DefaultColumn";
import * as yup from "yup";

const validations = yup.object({
    id_region: yup.string().required("Area/Kota wajib diisi"),
    email: yup.string().required("Email wajib diisi"),
    password: yup.string().required("Password wajib diisi"),
});

const TABLE_COLUMN = (onEdit, onDelete) => [
    {
        accessorKey: 'customer_name',
        header: 'Nama Pelanggan',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'address',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
        header: 'Alamat',
    },
    {
        accessorKey: 'region.region_name',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
        header: 'Area/Kota',
    },
    {
        accessorKey: 'user_sale.sales_name',
        header: 'Sales',
        cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
        accessorKey: 'action',
        header: 'Aksi',
        size: 125,
        cell: info => <ActionColumn {...info} onClickEdit={onEdit} onClickDelete={onDelete} />,
        enableSorting: false,
    },
];

export {
    TABLE_COLUMN,
    validations,
}
