import React, { useState } from "react";
import EyeIcon from "assets/icons/eye-ic.png";
import EyeOffIcon from "assets/icons/eye-off-ic.png";

function InputField(props) {
  const {
    label,
    id,
    extra,
    type,
    placeholder,
    variant,
    state,
    disabled,
    required,
    register = {},
    errors = { message: "", show: false },
    ...rest
  } = props;
  const [visible, setVisible] = useState(false);

  const passwordType = visible ? "text" : "password";

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`text-sm text-textDefault dark:text-white ${
          variant === "auth" ? "font-medium" : "font-bold"
        }`}
      >
        {label} {required && <span className="text-[#DF2A36]">*</span>}
      </label>
      <div className="relative">
        <input
          disabled={disabled}
          type={(type === "password" || type === "pin") ? passwordType : type}
          placeholder={placeholder}
          onFocus={(e) =>
            e.target.addEventListener(
              "wheel",
              function (e) {
                e.preventDefault();
              },
              { passive: false },
            )
          }
          className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
            disabled === true
              ? "!border-none cursor-not-allowed !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
              : state === "error"
              ? "border-[#DF2A36] dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
              : state === "success"
              ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
              : "border-gray-200 dark:!border-white/10 dark:text-white"
          }`}
          {...type === 'pin' && { maxLength: '6' }}
          {...register}
          {...rest}
        />
        {(type === "password" || type === "pin") && (
          <img
            onClick={() => setVisible((p) => !p)}
            className="absolute right-4 top-3 cursor-pointer"
            src={visible ? EyeIcon : EyeOffIcon}
            alt="eye"
            width={24}
            height={24}
          />
        )}
      </div>
      {errors.show && <span className="text-[#DF2A36] text-[12px]">{errors.message}</span>}
    </div>
  );
}

export default InputField;
