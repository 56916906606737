import { useContext, useEffect, useState } from "react";
import Filter from "./component/Filter";
import WidgetList from "./component/WidgetList";
import { LayoutContext } from "context/LayoutContext";
import { ContextTransaction } from "./context/ContextProvider";
import { useTransactionList } from "./context/useTransactionList";
import Card from "components/card";
import FilterTable from "./component/FilterTable";
import List from "./component/List";
import { getTransaction } from "apis/transaction";
import { removeEmptyValues } from "helper/index";
import { useToast } from "@chakra-ui/react";
import ModalDetail from "./component/ModalDetail";

const Transaction = () => {
  const { setHeaderComponent } = useContext(LayoutContext);
  const [detailData, setDetailData] = useState(null);
  const { open, setOpen, query, setQuery, tableData, setTableData } =
    useTransactionList();
  const toast = useToast();

  const handleOpenDetail = (item) => {
    setDetailData(item)
    setOpen(true)
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await getTransaction(removeEmptyValues(query.watch));

        setTableData(response.data);
        setQuery((prevQuery) => ({
          ...prevQuery,
          total_data: response.total,
          total_page: response.last_page,
          total_value: response.total_value,
          unpaid_value: response.unpaid_value,
          paid_value: response.paid_value,
        }));
      } catch (err) {
        toast({
          title: "Gagal",
          description: err,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.watch]);

  useEffect(() => {
    setHeaderComponent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContextTransaction.Provider
      value={{
        open,
        setOpen,
        query,
        setQuery,
        tableData,
        handleOpenDetail,
        detailData,
      }}
    >
      <Filter />

      <WidgetList />

      <Card extra="w-full h-full p-6 sm:overflow-x-auto flex-grow mt-5 flex flex-col">
        <FilterTable />

        <List />

        <ModalDetail />
      </Card>
    </ContextTransaction.Provider>
  );
};

export default Transaction;
