import { MdCompareArrows, MdUploadFile } from 'react-icons/md';
import { TYPE_ENUM } from '../config/enum';

const TypeColumn = ({ type }) => {
    let value = { label: '', icon: '' };

    switch (type) {
        case TYPE_ENUM.TRANSFER:
            value = { label: 'Transfer Stok', icon: <MdCompareArrows /> };
            break;

        case TYPE_ENUM.PURCHASE:
            value = { label: 'Purchase Order', icon: <MdUploadFile /> };
            break;

        default:
            break;
    }

    return (
        <div
            className="flex gap-2 items-center w-fit text-xs"
        >
            {value.icon}
            {value.label}
        </div>
    );
};

export default TypeColumn;
