import { useContext } from 'react';
import { ContextHistoryStock } from '../context/ContextProvider';
import Text from 'components/Text';
import ModalContainer from 'components/Modal';
import moment from 'moment';
import { currency, numberSeparator } from 'helper/index';

const ModalDetail = () => {
    const { open, setOpen, setOpenSN, detailData } = useContext(ContextHistoryStock);

    return (
        <ModalContainer
            isOpen={open}
            modalTitle={`Detail Laporan Kas Sales`}
            onClose={() => { setOpen(false) }}
            hideButton
        >
            <div className='flex flex-col gap-4 w-[800px] pr-2 mb-3'>
                <div className='flex flex-col px-4 w-full border-[1px] rounded-xl py-5 gap-4'>
                    <div className='flex w-full justify-between'>
                        <Text variant="linkBold" content="Tanggal" />
                        <Text variant="link" content={moment(detailData?.created_at).format('DD MMM YYYY')} />
                    </div>
                    <div className='flex w-full justify-between'>
                        <Text variant="linkBold" content="Shift" />
                        <Text variant="link" content={`${moment(detailData?.date_open).format('DD MMM YYYY HH:mm')} - ${detailData?.date_close ? moment(detailData?.date_close).format('DD MMM YYYY HH:mm') : ''}`} />
                    </div>
                    <div className='flex w-full justify-between'>
                        <Text variant="linkBold" content="Nama Sales" />
                        <Text variant="link" content={detailData?.user_sales?.sales_name} />
                    </div>
                    <div className='flex w-full justify-between'>
                        <Text variant="linkBold" content="Stok Awal" />
                        <Text variant="link" content={detailData?.initialStock} />
                    </div>
                </div>

                <div className='flex flex-col px-4 w-full border-[1px] rounded-xl py-5 gap-4'>
                    <Text variant="h3" content="Penerimaan Kasir" />
                    <div className="border-b-[1px] border-[#E4E5E8]" />
                    {detailData?.payment_list && detailData?.payment_list && detailData?.payment_list.map(item => (
                        <>
                            <div className='flex w-full justify-between'>
                                <Text variant="linkBold" content={item.payment_method_name} />
                                <Text variant="linkBold" content={currency(item.payment_method_value)} />
                            </div>
                            {item.payment_method_bank_name.map((childItem, childIdx) => (
                                <div className='flex w-full justify-between'>
                                    <Text variant="link" customStyle='ml-4' content={(childItem.bank_name !== '' && childItem.bank_name !== '-') ? childItem.bank_name : `${item.payment_method_name} ${childIdx + 1}`} />
                                    <Text variant="link" content={currency(item.payment_method_value)} />
                                </div>
                            ))}
                        </>
                    ))}
                </div>

                <div className='flex flex-col px-4 w-full border-[1px] rounded-xl py-5 gap-4'>
                    <Text variant="h3" content="Rincian Transaksi Kasir" />
                    <div className="border-b-[1px] border-[#E4E5E8]" />
                    <div className='flex w-full justify-between'>
                        <Text variant="link" content="Transaksi Selesai" />
                        <Text variant="link" content={numberSeparator(detailData?.paid_transaction_count || 0)} />
                    </div>
                    <div className='flex w-full justify-between'>
                        <Text variant="link" content="Transaksi Belum Terbayar" />
                        <Text variant="link" content={numberSeparator(detailData?.unpaid_transaction_count || 0)} />
                    </div>
                    <div className="border-b-[1px] border-[#E4E5E8]" />
                    <div className='flex w-full justify-between'>
                        <Text variant="linkBold" content="Total Transaksi Selesai" />
                        <Text variant="linkBold" content={currency(detailData?.paid_transaction_sum || 0)} />
                    </div>
                    <div className='flex w-full justify-between'>
                        <Text variant="linkBold" content="Total Transaksi Belum Terbayar" />
                        <Text variant="linkBold" content={currency(detailData?.unpaid_transaction_sum || 0)} />
                    </div>
                </div>
            </div>
            
        </ModalContainer>
    );
};

export default ModalDetail;
