import { useContext, useEffect, useState } from 'react';
import { ContextPurchaseOrder } from '../context/ContextProvider';
import Table from 'components/Table';
import { TABLE_COLUMN_PRODUCT } from '../config/enum';
import InputSearch from 'components/Form/InputSearch';
import ModalContainer from 'components/Modal';
import { debounce, removeEmptyValues } from 'helper/index';
import { getProduct } from 'apis/product';
import InputMultipleSelect from 'components/Form/InputMultipleSelect';
import { getProductCategory } from 'apis/product_category';
import { useFormContext } from 'react-hook-form';

const ModalProduct = () => {
    const forms = useFormContext();
    const { openProduct, setOpenProduct, productList, setProductList, toast } = useContext(ContextPurchaseOrder);
    const [query, setQuery] = useState({ watch: { keyword: '', page: 1, limit: 5, id_category: '' }, total_data: 0, total_page: 0 });
    const [loading, setLoading] = useState(false);
 
    const [selectedProduct, setSelectedProduct] = useState(forms.watch('po_details').map(p => p.id_product));
    const [productDataTemp, setProductDataTemp] = useState([]);

    const selectProduct = () => {
        const products = productDataTemp.filter(item => selectedProduct.includes(item.id_product));

        const mergedProducts = [...forms.watch('po_details'), ...products];
        const uniqueProducts = Array.from(new Map(mergedProducts.map(p => [p.id_product, p])).values());
        forms.reset({ ...forms.watch(), po_details: uniqueProducts });

        setProductDataTemp([]);
        setOpenProduct(false);
    }

    const onCheckTable = (param) => {
        setSelectedProduct(prev => 
            prev.includes(param.id_product) 
                ? prev.filter(t => t !== param.id_product)
                : [...prev, param.id_product]
        );
    }

    useEffect(() => {
        if (openProduct) {
            (async () => {
                try {
                    setLoading(true);
                    const queryParams = removeEmptyValues({
                        ...query.watch,
                        id_gudang: forms.watch('id_gudang'),
                        id_category: forms.watch('id_category')
                    });

                    const res = await getProduct(queryParams);
                    const formatted = res.data.map(item => ({
                        ...item,
                        checked: false,
                        qty: 1,
                        price: item.capital_price,
                    }));

                    setProductList(formatted);

                    setProductDataTemp(prev => {
                        const combinedArray = [...prev, ...formatted];
                        const uniqueArray = Array.from(new Map(combinedArray.map(p => [p.id_product, p])).values());
                        return uniqueArray;
                    });

                    setQuery(prevQuery => ({
                        ...prevQuery,
                        total_page: res.last_page,
                        total_data: res.total,
                    }));
                } catch (error) {
                    toast({
                        title: 'Gagal mendapatkan data produk',
                        status: "error",
                        isClosable: true,
                        position: "top-center",
                    });
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [openProduct, query.watch]);

    return (
        <ModalContainer
            isOpen={openProduct}
            modalTitle={'Pilih Product'}
            onClose={() => setOpenProduct(false)}
            onSubmit={selectProduct}
        >
            <div className="mb-3 flex w-full gap-3">
                <div className="flex-grow">
                    <InputSearch
                        rounded
                        changeHandler={debounce(e => 
                            setQuery(prevQuery => ({
                                ...prevQuery, 
                                watch: { ...prevQuery.watch, keyword: e.target.value }
                            })), 500
                        )}
                    />
                </div>
                <div className="w-1/3">
                    <InputMultipleSelect
                        required
                        fetchList={getProductCategory}
                        keyLabel="category_name"
                        keyValue="id_category"
                        initialLabel="Semua Kategori"
                        onChange={value => {
                            forms.setValue("id_category", value, { shouldValidate: true });
                        }}
                        value={forms.watch("id_category")}
                    />
                </div>
            </div>
            {loading && 'Loading...'}
            <Table
                columnsData={TABLE_COLUMN_PRODUCT(onCheckTable)}
                tableData={productList.map(prd => ({
                    ...prd,
                    checked: selectedProduct.includes(prd.id_product)
                }))}
                paginationCallback={(page, offset) =>
                    setQuery(prevQuery => ({
                        ...prevQuery, 
                        watch: { ...prevQuery.watch, page, limit: offset }
                    }))
                }
                totalData={query.total_data}
                currentPage={query.watch.page}
                perPage={query.watch.limit}
                pageCount={query.total_page}
            />
        </ModalContainer>
    );
};

export default ModalProduct;
