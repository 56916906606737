import { useState } from "react";

export const usePromoList = () => {
    const [openCreate, setOpenCreate] = useState(false);
    const [optionSelected, setSelected] = useState();

    const handleChange = (selected) => setSelected(selected);

    return {
        openCreate,
        setOpenCreate,
        optionSelected,
        handleChange
    }
}