import InputSelect from "components/Form/InputSelect";
import Text from "components/Text";
import { useEffect, useState } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import ReactPaginate from "react-paginate";

const SELECT_DATA = [
  {
    label: "5",
    value: 5,
  },
  {
    label: "10",
    value: 10,
  },
  {
    label: "25",
    value: 25,
  },
  {
    label: "50",
    value: 50,
  },
  {
    label: "100",
    value: 100,
  },
];

const Pagination = ({
  pageCount = 1,
  perPage,
  currentPage,
  totalData,
  callback,
}) => {
  const [offset, setOffset] = useState(`${perPage}`);
  const [page, setPage] = useState(currentPage - 1);
  const handlePageClick = (event, offset) =>
    callback(event.selected + 1, offset);

  useEffect(() => {
    setPage(currentPage - 1);
  }, [currentPage]);

  return (
    <div className="mt-5 flex w-full gap-4 align-bottom">
      <div className="flex w-1/2 items-center gap-2">
        <Text variant="link" content="Menampilkan" />
        <div className="w-[55px]">
          <InputSelect
            size="xs"
            optionList={SELECT_DATA}
            value={offset}
            onChange={(e) => {
              setPage(0);
              handlePageClick({ selected: 0 }, e.target.value);
              setOffset(e.target.value);
            }}
          />
        </div>
        <Text
          variant="link"
          content={`data per halaman, dari ${totalData} data`}
        />
      </div>
      <div className="flex w-1/2 justify-end">
        <ReactPaginate
          breakLabel="..."
          nextLabel={<MdChevronRight />}
          onPageChange={(e) => {
            handlePageClick(e, offset);

            setPage(e.selected);
          }}
          pageRangeDisplayed={5}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          forcePage={page}
          previousLabel={<MdChevronLeft />}
          renderOnZeroPageCount={null}
          containerClassName="flex gap-2 text-sm"
          pageClassName="border-[1px] border-brand-500 rounded-lg px-3 py-1 font-bold"
          activeClassName="bg-brand-500 text-white"
          breakClassName="border-[1px] border-brand-500 rounded-lg px-3 py-1"
          nextClassName="text-lg pt-2"
          previousClassName="text-lg pt-2"
        />
      </div>
    </div>
  );
};

export default Pagination;
